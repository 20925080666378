import SidebarFilters from '../components/sidebar-filters';
import AccordionConfig from '../components/accordion-config';
import SwiperConfig from '../components/swiper-config';
import Narvar from '../narvar/ship-edd';
import Navigation from '../components/navigation';
import utilities from '../components/utilities';


export default {
  init() {
    // JavaScript to be fired on all pages

    /**
     * Initialize the utilities object
     */
    utilities();

    /**
     * Initialize Swiper.js
     */
    const swiper = new SwiperConfig();
    swiper.init();

    /**
     * Initialize the Narvar object.
     */
    const narvar = new Narvar(

      /* The shipping address form elements from WooCommerce checkout. */
      this.shippingData = {
        order_date:$( '#order_date' ),
        origin_zip:$( '#origin_zip' ),
        dest_zip:$( '#dest_zip' ),
        origin_country:$( '#origin_country' ),
        dest_country:$( '#dest_country' ),
        dc_id:$( '#dc_id' ),
        carrier_code:$( '#carrier_code' ),
        service_code:$( '#service_code' ),
      }

    );
    narvar.init();

    /**
     * Initialize the navigation object
     */
    const navigation = new Navigation();
    navigation.init();

    /**
     * Initialize the jQuery UI Accordion Widget
     */
    const accordion = new AccordionConfig();
    accordion.init();

    /**
     * Initialize the siderbar filters object
     */
    const sidebarFilters = new SidebarFilters();
    sidebarFilters.init();

    /**
     * Hide the Hat Customizer Personalization form from the WC product single.
     *
     * @todo This could be moved into a route for WC single products.
     */
    $( '.gform_variation_wrapper.gform_wrapper.single_variation_wrap' ).css('display', 'none');

    /**
     * Converts all instances of ™®© into superscript
     */

    $('body :not(script)').contents().filter(function() {
      return this.nodeType === 3;
    }).replaceWith(function() {
      return this.nodeValue.replace(/[™®©]/g, '<sup>$&</sup>');
    });

  },
  finalize() {
  },
};
