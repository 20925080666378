
const _filter = require('lodash.filter');
const _forEach = require('lodash.foreach');

export default {
  name: 'hat-customizer-logos',

  props: {
    categories: {
      type: Array,
      required: true,
    },

    categoryFilter: {
      type: Number,
      required: true,
    },

    product: {
      type: Object,
      required: true,
    },

    searchFilter: {
      type: String,
      required: true,
    },

    selectedVariation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      allLogos: [],
      loading: true,
      selectedHatIcon: { url: '', width: '', height: '' },
      selectedLogo: {
        additional_charge: 0,
      },
      selectedLogoId: 0,
    };
  },

  computed: {
    categoryOption() {
      return parseInt(this.categoryFilter, 10);
    },

    categoryOptionLabel() {
      var vm = this;
      var label = vm.categoryOption;
      _forEach(vm.categories, function(category) {
        if (vm.categoryOption === category.id) {
          label = category.title;

          return;
        }
      });

      return label.toString();
    },

    logos() {
      var logos = this.allLogos;

      /** Filter down the logos. */
      logos = this.filterSrc(logos);
      logos = this.filterSearch(logos);
      logos = this.filterExcluded(logos);
      logos = this.filterCategory(logos);

      /** Remove the selected logo if is not in the filtered logos. */
      this.removeSelectedLogoIfFilteredOut(logos);

      return logos;
    },

    searchValue() {
      return this.searchFilter.toString().toLowerCase().trim();
    },

  },

  methods: {
    updateSelectedLogo(logo) {
      this.selectedLogoId = parseInt(logo.id, 10);
      this.selectedHatIcon = logo.hatIcon;
      this.selectedLogo = logo;

      this.change();
    },

    clearSelectedLogo() {
      this.selectedLogoId = 0;
      this.selectedHatIcon = { url: '', width: '', height: '' };
      this.selectedLogo = {
        additional_charge: 0,
      };

      this.change();
    },

    change() {
      window.Bus.$emit('mdghc.selected-logo-updated', {
        id: this.selectedLogoId,
        hatIcon: this.selectedHatIcon,
        selectedLogo: this.selectedLogo,
      });
    },

    removeSelectedLogoIfFilteredOut(logos) {
      var selectedLogo = parseInt(this.selectedLogoId, 10);

      /* No point in removing it if nothing is selected. */
      if (0 === selectedLogo) {
        return;
      }

      /* Look to see if we have the selected logo in the logs. */
      for (var i = logos.length - 1; i >= 0; i--) {
        if (logos[i].id === selectedLogo) {
          return;
        }
      }

      /* Remove selected logo if it is not in the logos. */
      this.clearSelectedLogo();
    },

    setAllLogos(callback) {
      let options = {
        params: {
          action: 'hat_customizer_logos',
          product_id: this.product.id,
        },
      };

      this.$http.get('/', options).then(response => {
        var logos =
          typeof response.data.data.logos === 'undefined'
            ? []
            : response.data.data.logos;
        callback(logos);
      });
    },

    /**
     * Filter the logos by matching any excluded Product SKUs or Product IDs.
     * @uses _filter Lodash per method library.
     * @link https://lodash.com/docs/4.17.15#filter
     * @param array logos A collection array of logo objects returned from PHP AJAX request.
     * @return array The filtered logos array minus any found exclusions.
     */
    filterExcluded(logos) {
      var vm = this;

      return _filter(logos, function(logo) {
        let matchColorSKU = -1 !== logo.excludedBySKU.indexOf(vm.selectedVariation.variation_id);
        let matchProductSKU = -1 !== logo.excludedBySKU.indexOf(vm.product.id);
        let matchProductLogo = -1 !== logo.excludedByProduct.indexOf(vm.product.id);

        return !matchProductSKU && !matchColorSKU && !matchProductLogo;
      });
    },

    filterCategory(logos) {
      var vm = this;
      var catId = parseInt(vm.categoryOption, 10);

      if (0 === catId) {
        return logos;
      }

      return logos.filter(function(logo) {
        return -1 !== logo.categoryIds.indexOf(catId);
      });
    },

    search(searchIn, callback) {
      var vm = this;

      if (typeof searchIn === 'undefined' || searchIn === null) {
        return false;
      }

      if (typeof searchIn !== 'object' && typeof searchIn !== 'undefined') {
        var result = -1 !== searchIn.toString().indexOf(vm.searchValue);

        return callback(result);
      }

      _forEach(searchIn, function(value) {
        vm.search(value, callback);
      });
    },

    filterSrc(logos) {
      const defaultLogos = [];

      for(let i = 0; i < logos.length; i++) {
          defaultLogos.push(logos[i]);
      }

      return defaultLogos;
    },

    filterSearch(logos) {
      var vm = this;

      if ('' === vm.searchValue) {
        return logos;
      }

      return logos.filter(function(logo) {
        var searchFilterFound = false;
        logo.title = logo.title.toLowerCase();
        vm.search(
          logo,
          result => (searchFilterFound = result ? true : searchFilterFound)
        );

        return searchFilterFound;
      });
    },
  },

  mounted() {
    this.setAllLogos( function(allLogos) {
      this.allLogos = allLogos;
      this.loading = false;
    }.bind(this));

    this.change();
  },
};
