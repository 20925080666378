
export default {
  props: {
    dataVariations: {
      type: [Object, Array],
      required: false,
    },
    dataAttributes: {
      type: Object,
      required: true,
    },
    dataAttributeName: {
      type: String,
      required: true,
    },
    dataUnselectedAttributes: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    dataColorValue: {
      type: String,
      default: '',
    },
    dataSizeValue: {
      type: String,
      default: '',
    },
    dataTitle: {
      type: String,
    },
    dataResetLabel: {
      type: String,
      default: 'Reset selection',
    },
    dataOutputName: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      previousBandVariation: null, // Store the previously selected band variation
    };
  },

  computed: {
    outOfStockAttributeIds() {
      // console.log('Re-computing outOfStockAttributeIds');
      let attributeIds = this.unselectedAttributes
        .filter(attribute => {
          if (attribute.attributeName !== this.attributeName) {
            return false;
          }
          return !attribute.variation.get('is_in_stock');
        })
        .map(attribute => attribute.id)
        .unique();

      let absentVariationsAttributesIds = this.checkAbsentVariationsAttributes();
      if (absentVariationsAttributesIds.length) {
        absentVariationsAttributesIds.forEach(id => attributeIds.push(id));
      }

      return [...new Set(attributeIds)]; // Ensure unique IDs
    },

    unselectedAttributes() {
      return this.dataUnselectedAttributes;
    },

    attributes() {
      return this.dataAttributes.sortBy('sort_order');
    },

    activeValue() {
      // console.log('Re-computing activeValue');
      let attribute = this.attributes.where('slug', this.value).first();
      return typeof attribute === 'undefined' ? {} : attribute;
    },

    attributeName() {
      return this.dataAttributeName;
    },
  },

  watch: {
    dataSizeValue(newValue, oldValue) {
      // console.log('dataSizeValue changed:', oldValue, '->', newValue);
      this.previousBandVariation = this.activeValue.slug; // Store the current selection
      this.updateAttributes();
      this.$nextTick(() => {
        if (this.previousBandVariation) {
          this.reselectBandVariation(this.previousBandVariation);
        }
      });
    },
    outOfStockAttributeIds(newValue) {
      if (!newValue.includes(this.activeValue.id)) {
        return;
      }
      this.$nextTick(() => this.clearSelection());
    },
  },

  methods: {
    clearSelection() {
      this.$emit('input', '');
      this.$refs.button.forEach($el => $el.blur());
    },

    handleSelection(attribute) {
      let slug = attribute.slug || '';
      if (this.activeValue && this.activeValue.slug === slug) {
        return this.clearSelection();
      }
      this.$emit('input', slug);
    },

    checkAbsentVariationsAttributes() {
      let attributesIds = [];
      this.attributes.forEach((attribute) => {
        if (this.checkVariantsAttributeExist(attribute.id)) {
          attributesIds.push(attribute.id);
        }
      });
      return attributesIds;
    },

    checkVariantsAttributeExist(attr_id) {
      let result = true;
      if (this.dataVariations) {
        this.dataVariations.forEach((variant) => {
          if (variant.attributes['attribute_pa_band-color'].id === attr_id
              && (typeof variant.attributes['attribute_pa_color'] === 'undefined' || variant.attributes['attribute_pa_color'].slug === this.dataColorValue)
              && (typeof variant.attributes['attribute_pa_size'] === 'undefined' || variant.attributes['attribute_pa_size'].slug === this.dataSizeValue)
          ) {
            result = false;
          }
        });
      }
      return result;
    },

    updateAttributes() {
      // Recompute attributes based on the new size value
      this.outOfStockAttributeIds = this.unselectedAttributes
        .filter(attribute => {
          if (attribute.attributeName !== this.attributeName) {
            return false;
          }
          return !attribute.variation.get('is_in_stock');
        })
        .map(attribute => attribute.id)
        .unique();

      let absentVariationsAttributesIds = this.checkAbsentVariationsAttributes();
      if (absentVariationsAttributesIds.length) {
        absentVariationsAttributesIds.forEach(id => this.outOfStockAttributeIds.push(id));
      }
      this.outOfStockAttributeIds = [...new Set(this.outOfStockAttributeIds)]; // Ensure unique IDs
      this.clearSelection(); // Reset selection if needed
    },

    reselectBandVariation(slug) {
      let attribute = this.attributes.find(attr => attr.slug === slug);
      if (attribute && !this.outOfStockAttributeIds.includes(attribute.id)) {
        this.$emit('input', slug);
      }
    },
  },
};
