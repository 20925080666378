
import ProductFilterMultiSelect from './ProductFilterMultiSelect';
import ProductFilterSelections from './ProductFilterSelections';
// import { setTimeout } from 'timers';

export default {
  props: {
    dataFilters: {
      type: Array,
    },

    dataAction: {
      type: String,
      default: '?',
    },

    dataSearch: {
      type: String,
      default: '',
    },
  },

  components: {
    ProductFilterMultiSelect,
    ProductFilterSelections,
  },

  data() {
    return {
      filters: this.dataFilters,
      search: this.dataSearch,
    };
  },

  computed: {},

  methods: {
    handleReset() {
      this.filters = this.filters.map(filter => {
        filter.values = [];
        return filter;
      });

      this.search = '';

      this.$nextTick(() => this.$refs['form'].submit());
    },
  },

  watch: {
    dataFilters() {
      this.filters = this.dataFilters;
    },
  },
};
