class AccordionConfig {

  // Initialize object properties
  constructor() {
    this.sidebarFilters = $('.sidebar-accordion-js .widget');
    this.subcatAccordion = $('.subcat-accordion-js');
    this.general = $('.accordion-js');
  }

  init() {
    this.sidebarFilters.accordion({
      collapsible: true,
      header: 'h3',
      icons: false,
      animate: {
        duration: 750,
        down: 750,
      },
      heightStyle: 'content',
    });

    this.subcatAccordion.accordion({
      active: false,
      collapsible: true,
      header: 'h2:first-child',
      icons: false,
      animate: {
        duration: 500,
        down: 750,
      },
      heightStyle: 'content',
    });

    this.general.accordion({
      active: false,
      collapsible: true,
      header: 'h3',
      icons: false,
      animate: {
        duration: 250,
        down: 250,
      },
      heightStyle: 'content',
    });
  }
}

export default AccordionConfig;
