
export default {
  props: {
    dataMessage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
