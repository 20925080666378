
export default {
  props: {
    dataAttributes: {
      type: Object,
      required: true,
    },

    dataAttributeName: {
      type: String,
      required: true,
    },

    dataUnselectedAttributes: {
      type: Object,
      required: true,
    },

    value: {
      type: String,
      default: '',
    },

    dataTitle: {
      type: String,
    },

    dataResetLabel: {
      type: String,
      default: 'Reset selection',
    },

    dataOutputName: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    outOfStockAttributeIds() {
      return this.unselectedAttributes
        .filter(attribute => {
          if (attribute.attributeName !== this.attributeName) {
            return false;
          }

          return !attribute.variation.get('is_in_stock');
        })
        .map(attribute => attribute.id)
        .unique();
    },

    unselectedAttributes() {
      return this.dataUnselectedAttributes;
    },

    attributes() {
      return this.dataAttributes.sortBy('sort_order');
    },

    activeValue() {
      let attribute = this.attributes.where('slug', this.value).first();
      return typeof attribute === 'undefined' ? {} : attribute;
    },

    attributeName() {
      return this.dataAttributeName;
    },
  },

  watch: {
    outOfStockAttributeIds(newValue) {
      if (!newValue.contains(this.activeValue.id)) {
        return;
      }

      this.$nextTick(() => this.clearSelection());
    },
  },

  methods: {
    clearSelection() {
      this.$emit('input', '');
      this.$refs.button.forEach($el => $el.blur());
    },

    handleSelection(attribute) {
      let slug = typeof attribute.slug === 'undefined' ? '' : attribute.slug;

      if (this.activeValue && this.activeValue.slug === slug) {
        return this.clearSelection();
      }

      this.$emit('input', slug);
    },
  },
};
