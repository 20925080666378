
/* Alert messages are WIP for a future iteration. */
import Alert from './Alert.vue';

const _mapValues = require('lodash.mapvalues');

export default {
  name: 'add-to-cart',

  components: {
    Alert,
  },

  props: {
    customizations: {
      type: Object,
      required: true,
    },

    enableAddLogo: {
      type: Boolean,
      default: false,
    },

    enableAddMonogram: {
      type: Boolean,
      default: false,
    },

    enableAddPersonalization: {
      type: Boolean,
      default: false,
    },

    inStock: {
      type: Boolean,
      default: true,
    },

    monogramPrice: {
      type: Number,
      default: 10,
    },

    personalizationPrice: {
      type: Number,
      default: 10,
    },

    product: {
      type: Object,
      required: true,
    },

    requireCustomizationToAddToCart: {
      type: Boolean,
      default: false,
    },

    selectedVariation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      errorMessage: '',
      isSubmitting: false,
      quantityText: '1',
      successMessage: '',
    };
  },

  computed: {
    /**
     * Collects and returns the selected logo's id and charge.
     * @returns {Object}
     */
    addLogo() {
      if (typeof this.customizations.addLogo === 'undefined') {
        return {};
      }

      return this.customizations.addLogo;
    },

    /**
     * Handles messages based on required customization props.
     * @returns {boolean}
     */
    customizationStillRequired() {
      /* If the customization is not required then there is no way that it is still required. */
      if (!this.requireCustomizationToAddToCart) {
        return false;
      }

      /* Check logo enabled only. */
      if (this.enableAddPersonalization && !this.enableAddLogo) {
        return !this.logoSelected();
      }

      /* Check personalization enabled only. */
      if (!this.enableAddPersonalization && this.enableAddLogo) {
        return !this.personalizationSelected();
      }

      /* Check both logo and personalization enabled. */
      if (!this.enableAddPersonalization && !this.enableAddLogo) {
        /* If something is selected then customization is no longer required. */
        return this.logoSelected() || this.personalizationSelected()
          ? false
          : true;
      }

      /* Check monogram enabled only. */
      if (!this.enableAddMonogram) {
        return !this.monogramSelected();
      }

      return false;
    },

    /**
     * Get/Set submit button disable/enable.
     * @returns {boolean}
     */
    isDisabled() {
      return (
        '' !== this.validationMessage ||
        this.isSubmitting ||
        !this.inStock ||
        !this.validSubmissionParamExists
      );
    },

    /**
     * Returns boolean based on personalization form input.
     * @returns {boolean}
     */
    isPersonalized() {
      let personalizedText = this.personalize.personalizedText;
      let threadColorId = parseInt(this.personalize.threadColorId, 10);
      let embroideryFontId = parseInt(
        this.personalize.embroideryFontId,
        10
      );
      if (
        0 === embroideryFontId ||
        !personalizedText ||
        0 === threadColorId
      ) {
        return false;
      }

      return true;
    },

    /**
     * Collects personalization input, gets/sets object.
     * @returns {Object}
     */
    personalize() {
      if (
        typeof this.customizations.personalize === 'undefined' ||
        this.customizations.personalize.length === 0
      ) {
        return {
          personalizedText: '',
          threadColorId: 0,
          embroideryFontId: 0,
        };
      }

      return this.customizations.personalize;
    },

    /**
     * Returns a boolean based on monogram form input.
     * @returns {boolean}
     */
    isMonogramed() {
      let firstInitialText = this.monogram.firstInitialText;
      let lastInitialText = this.monogram.lastInitialText;
      let middleInitialText = this.monogram.middleInitialText;
      let monogramThreadColorId = parseInt(this.monogram.monogramThreadColorId, 10);
      let monogramFontId = parseInt(
        this.monogram.monogramFontId,
        10
      );
      if (
        0 === monogramFontId ||
        !firstInitialText ||
        !lastInitialText ||
        !middleInitialText ||
        0 === monogramThreadColorId
      ) {
        return false;
      }

      return true;
    },

    /**
     * Collects monogram input, gets/sets object.
     * @returns {Object}
     */
    monogram() {
      if (
        typeof this.customizations.monogram === 'undefined' ||
        this.customizations.monogram.length === 0
      ) {
        return {
          firstInitialText: '',
          lastInitialText: '',
          middleInitialText: '',
          monogramThreadColorId: 0,
          monogramFontId: 0,
        };
      }

      return this.customizations.monogram;
    },

    /**
     * Calculates and returns the current order price based on form input and sets the {computed:price} value.
     * @returns {number}
     */
    price() {
      let price = parseFloat(this.product.price, 10);
      price = this.addPersonalizationPrice(price);
      price = this.addselectedLogoCharge(price);
      price = this.addMonogramPrice(price);

      return price * this.quantity;
    },

    /**
     * Returns the product quantity input.
     * @returns {number}
     */
    quantity() {
      let quantity = parseInt(this.quantityText, 10);
      return quantity;
    },

    /**
     * Handles messages based on required customization entries.
     * @returns {string}
     */
    requiredCustomizationMessage() {
      /* Logo enabled only. */
      if (this.enableAddLogo && !this.enableAddPersonalization && this.customizations.addLogo.selectedLogoId === 0) {
        return 'Please select a logo above before continuing.';
      }

      /* Personalization enabled only. */
      if (!this.enableAddLogo && this.enableAddPersonalization && !this.isPersonalized) {
        return 'Please fill out personalization details above before continuing.';
      }

      /* Both logo and personalization enabled. */
      if ((this.enableAddLogo && this.customizations.addLogo.selectedLogoId === 0) && (this.enableAddPersonalization && !this.isPersonalized)) {
        return 'Please select a logo or fill out personalization details above before continuing.';
      }

      /* Monogram enabled only. */
      if (this.enableAddMonogram && !this.isMonogramed) {
        return 'Please fill out monogram details above before continuing.';
      }

      return '';
    },

    /**
     * Returns the selected logo's up charge amount.
     * @returns {number}
     */
    selectedLogoCharge() {
      if (!this.customizations.addLogo.selectedLogoCharge) {
        return 0;
      }

      return parseInt(this.customizations.addLogo.selectedLogoCharge, 10);
    },

    /**
     * Collects input values to pass into the GF embedded form fields.
     * @returns {Object}
     */
    submissionParams() {
      let params = {
        action: 'add_to_cart_personalized',
        logo_id: this.addLogo.selectedLogoId,
        logo_charge: this.selectedLogoCharge,
        personalized_text: this.personalize.personalizedText,
        thread_color_id: this.personalize.threadColorId,
        embroidery_font_id: this.personalize.embroideryFontId,
        first_initial_text: this.monogram.firstInitialText,
        last_initial_text: this.monogram.lastInitialText,
        middle_initial_text: this.monogram.middleInitialText,
        monogram_thread_color_id: this.monogram.monogramThreadColorId,
        monogram_font_id: this.monogram.monogramFontId,
        product_id: this.product.id,
        variation_id: this.selectedVariation.variation_id,
        quantity: parseInt(this.quantity),
      };

      params = _mapValues(params, function(value) {
        value = typeof value === 'undefined' ? '' : value;
        value = null === value ? '' : value;

        return value;
      });

      return params;
    },

    /**
     * Validates submission parameters.
     * @returns {boolean}
     */
    validSubmissionParamExists() {
      let params = this.submissionParams;
      let logo = false;
      let personalizationRequested = false;
      let personalizationDataReady = false;

      if (this.enableAddLogo && this.enableAddPersonalization) {
        /* Check logo. */
        logo = params.logo_id !== 0;

        /* Check personalization. */
        if (params.personalized_text !== '' || params.thread_color_id !== 0 || params.embroidery_font_id !== 0) {
          personalizationRequested = true;
          personalizationDataReady =
            params.personalized_text !== '' &&
            params.thread_color_id !== 0 &&
            params.embroidery_font_id !== 0;
        }

        /* Has logo & has personalization. */
        if (logo && personalizationRequested && personalizationDataReady) {
          return true;
        }

        /* Has logo & NO personalization. */
        if (logo && !personalizationRequested) {
          return true;
        }

        /* NO logo & has personalization. */
        if (!logo && personalizationRequested && personalizationDataReady) {
          return true;
        }

        return false;
      }

      /* Check logo. */
      if (this.enableAddLogo && params.logo_id !== 0) {
        return true;
      }

      /* Check personalization. */
      if (this.enableAddPersonalization) {
        let personalizationDataExists =
          params.personalized_text !== '' &&
          params.thread_color_id !== 0 &&
          params.embroidery_font_id !== 0;

        if (personalizationDataExists) {
          return true;
        }
      }

      /* Check monogram. */
      if (this.enableAddMonogram) {
        let monogramDataExists =
          params.first_initial_text !== '' &&
          params.last_initial_text !== '' &&
          params.middle_initial_text !== '' &&
          params.monogram_thread_color_id !== 0 &&
          params.monogram_font_id !== 0;

        if (monogramDataExists && this.monogramValid) {
          return true;
        }
      }

      return false;
    },

    /**
     * Handles product in stock/out of stock message for the Alert subcomponent.
     * @returns {string}
     */
    validationMessage: {
      get() {
        /* Handle out of stock message. */
        if (!this.inStock) {
          return 'Out of stock';
        }

        /* Handle personalization valid message. */
        var personalizationValidMessage = this.personalizationValid();
        if ('' !== personalizationValidMessage) {
          return personalizationValidMessage;
        }

        return '';
      },

      set() {},
    },
  },

  methods: {
    /**
     * Get/Set the selected logo up charge.
     * @returns {number}
     */
    addselectedLogoCharge(price) {
      if (!this.selectedLogoCharge) {
        return price;
      }

      return parseFloat(price, 10) + this.selectedLogoCharge;
    },

    /**
     * Get/Set the selected personalization price.
     * @returns {number}
     */
    addPersonalizationPrice(price) {
      if (!this.isPersonalized) {
        return price;
      }

      return parseFloat(price, 10) + this.personalizationPrice;
    },

    /**
     * Get/Set the selected monogram price.
     * @returns {number}
     */
    addMonogramPrice(price) {
      if (!this.isMonogramed) {
        return price;
      }

      return parseFloat(price, 10) + this.monogramPrice;
    },

    /**
     * Validates the personalization form entries.
     * @returns {string} - Returns a validation status string for the {component:Alert} subcomponent.
     */
    personalizationValid() {
      /* If its disabled then it should be valid... */
      if (!this.enableAddPersonalization) {
        return '';
      }

      let personalizedText =
        typeof this.personalize.personalizedText === 'undefined'
          ? ''
          : this.personalize.personalizedText;
      let threadColorId =
        typeof this.personalize.threadColorId === 'undefined'
          ? 0
          : parseInt(this.personalize.threadColorId, 10);
      let embroideryFontId =
        typeof this.personalize.embroideryFontId === 'undefined'
          ? 0
          : parseInt(this.personalize.embroideryFontId, 10);

      /* If there is not any text then we have nothing to worry about. */
      if (
        personalizedText === '' &&
        threadColorId === 0 &&
        embroideryFontId === 0
      ) {
        return '';
      }

      if (personalizedText === '') {
        return 'Please enter your personalization or clear out the embroidery font and thread color before continuing.';
      }

      /* Make sure a thread color and embroidery font. */
      if (threadColorId === 0 && embroideryFontId === 0) {
        return 'Please select a thread color and embroidery font before continuing.';
      }

      /* Make sure a thread color. */
      if (threadColorId === 0) {
        return 'Please select a thread color before continuing.';
      }

      /* Make sure we have an embroidery font. */
      if (embroideryFontId === 0) {
        return 'Please select an embroidery font before continuing.';
      }

      return '';
    },

    /**
     * Validates the monogram form entries.
     * @returns {string} - Returns a validation status string for the {component:Alert} subcomponent.
     */
    monogramValid() {
      /* If its disabled then it should be valid... */
      if (this.enableAddMonogram) {
        return '';
      }

      let firstInitialText =
        typeof this.monogram.firstInitialText === 'undefined'
          ? ''
          : this.monogram.firstInitialText;
      let lastInitialText =
        typeof this.monogram.lastInitialText === 'undefined'
          ? ''
          : this.monogram.lastInitialText;
      let middleInitialText =
        typeof this.monogram.middleInitialText === 'undefined'
          ? ''
          : this.monogram.middleInitialText;
      let monogramThreadColorId =
        typeof this.monogram.monogramThreadColorId === 'undefined'
          ? 0
          : parseInt(this.monogram.monogramThreadColorId, 10);
      let monogramFontId =
        typeof this.monogram.monogramFontId === 'undefined'
          ? 0
          : parseInt(this.monogram.monogramFontId, 10);

      /* If there is not any text then we have nothing to worry about. */
      if (
        '' === firstInitialText &&
        '' === lastInitialText &&
        '' === middleInitialText &&
        monogramThreadColorId === 0 &&
        monogramFontId === 0
      ) {
        return '';
      }

      if ('' === firstInitialText) {
        return 'Please enter your first initial before continuing.';
      }

      if ('' === lastInitialText) {
        return 'Please enter your last initial before continuing.';
      }

      /* Make sure a monogram thread color and monogram font. */
      if (monogramThreadColorId === 0 && monogramFontId === 0) {
        return 'Please select a monogram thread color and monogram font before continuing.';
      }

      /* Make sure a monogram thread color. */
      if (monogramThreadColorId === 0) {
        return 'Please select a monogram thread color before continuing.';
      }

      /* Make sure we have a monogram font. */
      if (monogramFontId === 0) {
        return 'Please select a monogram font before continuing.';
      }

      return '';
    },

    /**
     * Updates the WC embedded Gravity Form Personalization fields and submits
     * the Hat Customizer form collection to the WooCommerce cart.
     */
    submit() {
      if (this.isDisabled) {
        return;
      }

      /* Set GF Personalization fields. */
      var $checkbox = $( '.add-personalization-checkbox input[type="checkbox"]' );
      var $hidden = $( '.add-personalization-price input[type="hidden"]' );
      var $price = $( '.add-personalization-price input[type="text"]' );
      if ( this.isPersonalized === true || this.isMonogramed === true ) {
        $checkbox.attr( 'checked', true ); // The checkbox element will not work with the .prop() method, no explaination forthcoming
        $hidden.prop( 'disabled', false );
        $price.prop( 'disabled', false );
      }

      /* Set WC Quantity field. */
      $('.woocommerce-variation-add-to-cart .quantity input').val(
        parseInt(this.submissionParams.quantity, 10)
      );

      /* Set GF embroidery_font_id field. */
      if (this.submissionParams.embroidery_font_id) {
        $('.embroidery_font_id input').val(
          this.submissionParams.embroidery_font_id
        );
      }

      /* Set GF logo_id field. */
      if (this.submissionParams.logo_id) {
        $('.logo_id input').val(this.submissionParams.logo_id);
      }

      /* Set GF personalized_text field. */
      if (this.submissionParams.personalized_text) {
        $('.personalized_text input').val(
          this.submissionParams.personalized_text
        );
      }

      /* Set GF thread_color_id field. */
      if (this.submissionParams.thread_color_id) {
        $('.thread_color_id input').val(this.submissionParams.thread_color_id);
      }

      /* Set GF logo_charge field. */
      var logoCharge = parseInt(this.submissionParams.logo_charge, 10);
      if (!isNaN(logoCharge) && logoCharge > 0) {
        $('.js-additional-logo-charge input')
          .val(logoCharge)
          .trigger('change');
      }

      /* Set GF first_initial field. */
      if (this.submissionParams.first_initial_text) {
        $('.first_initial input').val(
          this.submissionParams.first_initial_text
        );
      }

      /* Set GF last_initial field. */
      if (this.submissionParams.last_initial_text) {
        $('.last_initial input').val(
          this.submissionParams.last_initial_text
        );
      }

      /* Set GF middle_initial field. */
      if (this.submissionParams.middle_initial_text) {
        $('.middle_initial input').val(
          this.submissionParams.middle_initial_text
        );
      }

      /* Set GF thread_color_id field. */
      if (this.submissionParams.monogram_thread_color_id) {
        $('.thread_color_id input').val(this.submissionParams.monogram_thread_color_id);
      }

      /* Set GF monogram_font_id field. */
      if (this.submissionParams.monogram_font_id) {
        $('.embroidery_font_id input').val(
          this.submissionParams.monogram_font_id
        );
      }

      /* Submit the WC add to cart form. */
      $('.single_add_to_cart_button').click();
    },

    /**
     * Get/Set the selected logo id of the computed addLogo Object.
     * @returns {boolean} Truthy/falsy value to add logo.
     */
    logoSelected() {
      return this.addLogo.selectedLogoId !== 0;
    },

    /**
     * Get/Set the computed items of the personalize Object.
     * @returns {boolean} Truthy/falsy value to add personalization.
     */
    personalizationSelected() {
      /* If the personalization is not valid then the customization is still required. */
      if (this.personalizationValid() !== '') {
        return false;
      }

      let fontSelected = this.personalize.embroideryFontId !== 0;
      let threadSelected = this.personalize.threadColorId !== 0;
      let textSelected = this.personalize.personalizedText !== '';

      return fontSelected && threadSelected && textSelected;
    },

    /**
     * Get/Set the computed items of the monogram Object.
     * @returns {boolean} Truthy/falsy value to add monogram.
     */
    monogramSelected() {
      /* If the monogram is not valid then the customization is still required. */
      if (this.monogramValid() !== '') {
        return false;
      }

      let fontSelected = this.monogram.monogramFontId !== 0;
      let threadSelected = this.monogram.monogramThreadColorId !== 0;
      let textSelected = this.monogram.firstInitialText !== '' && this.monogram.lastInitialText !== '';

      return fontSelected && threadSelected && textSelected;
    },

  },

  watch: {
    price(newPrice) {
      this.$emit('price-change', newPrice);
    },
  },
};
