
import collect from 'collect.js';

export default {
  props: {
    value: {
      type: Array,

      default() {
        return [];
      },
    },

    dataLabel: {
      type: String,
      required: true,
    },

    dataOptions: {
      type: Array,
      required: true,
    },

    dataName: {
      type: String,
    },

    dataTerm: {
      type: String,
    },

    dataType: {
      type: String,
    },

    dataQueryType: {
      default: 'or',
      validator(value) {
        return ['and', 'or'].indexOf(value.toLowerCase()) !== -1;
      },
    },
  },

  data() {
    return {
      selected: this.value,
      isOpen: false,
    };
  },

  computed: {
    label() {
      if (this.selected.length === 0) {
        return this.dataLabel;
      }

      let options = collect(this.dataOptions).whereIn('value', this.selected);
      let count = options.count();
      if (count === 1) {
        return options.first().label;
      }

      return `${this.dataLabel} (${count})`;
    },

    queryType() {
      return this.dataQueryType.toLowerCase();
    },

    name() {
      return this.dataName;
    },

    isTaxonomy() {
      return this.dataType === 'taxonomy';
    },

    isAttribute() {
      return this.dataType === 'attribute';
    },

    hasSelections() {
      return this.selected.length > 0;
    },

    selectName() {
      if (this.isTaxonomy) {
        return this.name;
      }

      return `filter_${this.name}`;
    },

    queryTypeName() {
      return `query_type_${this.name}`;
    },
  },

  methods: {
    handleClickOutside() {
      this.$refs['button'].blur();
      this.close();
    },

    emitChange() {
      this.$emit('input', this.selected);
    },

    toggleOpen() {
      return this.isOpen ? this.close() : this.open();
    },

    open() {
      this.isOpen = true;
      this.$emit('opened');
    },

    close() {
      this.isOpen = false;
      this.$emit('closeed');
    },

    handleCheckboxClick(selectedValue) {
      this.$nextTick(() => {
        if (this.selected.includes(selectedValue)) {
          this.selected = collect(this.selected)
            .reject(value => {
              return value === selectedValue;
            })
            .all();
        } else {
          this.selected.push(selectedValue);
        }
        this.emitChange();
      });
    },
  },

  watch: {
    value(newValue) {
      this.selected = newValue;
    },
  },
};
