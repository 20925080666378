
import collect from 'collect.js';
import priceFilter from './../../filters/price';

export default {
  props: {
    dataVariations: {
      type: [Object, Array],
      required: true,
    },

    dataSelectedVariation: {
      type: Object,
    },
  },

  data() {
    return {};
  },

  computed: {
    hasPriceRange() {
      return (
        this.minimumVariationDisplayPrice < this.maximumVariationDisplayPrice
      );
    },

    variations() {
      return this.dataVariations;
    },

    selectedVariation() {
      return this.dataSelectedVariation;
    },

    displayPrice() {
      if (!this.hasPriceRange || !this.selectedVariation) {
        return;
      }

      let price = this.selectedVariation.display_price;
      let regularPrice = this.selectedVariation.display_regular_price;
      if (price === regularPrice) {
        return priceFilter(regularPrice);
      }

      price = priceFilter(price);
      regularPrice = priceFilter(regularPrice);

      return `<del>${regularPrice}</del> ${price}`;
    },

    variationPrices() {
      if (!this.variations) {
        return collect({});
      }

      return collect(this.variations).map(variation => {
        return {
          display_price: variation.display_price,
          display_regular_price: variation.display_regular_price,
        };
      });
    },

    minimumVariationDisplayPrice() {
      return this.variationPrices.min('display_price');
    },

    maximumVariationDisplayPrice() {
      return this.variationPrices.max('display_price');
    },
  },

  methods: {},
};
