
export default {
  name: 'personalize-thread-color',

  props: {
    details: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeId: 0,
      activeLabel: 'Select a color',
    };
  },

  computed: {
    threadColors() {
      if (typeof this.details.threadColors === 'undefined') {
        return [];
      }

      return this.details.threadColors;
    },
  },

  methods: {
    onThreadColorClick(threadColor) {
      this.activeId = threadColor.id;
      this.activeLabel = threadColor.label;
      this.$emit('change', threadColor.id);
    },

    onThreadColorClearClick() {
      this.activeId = 0;
      this.activeLabel = 'Select a color';
      this.$emit('change', 0);
    },
  },
};
