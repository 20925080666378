/**
 * ShipStation Requester
 *
 * @class ShipStation
 */

class ShipStation {

  /**
   * Initialize the ShipStation requester object with all of its parameters.
   * @param {string} endpoint - The endpoint for this request. Available options: `/orders`, `/shipments`.
   * @param {object} inputOrderNumber - Selector for the input elemt contaiing the order number value.
   * @param {object} buttonSubmitRequest - Selector for the button element that triggers the request event.
   */
  constructor(endpoint, inputOrderNumber, buttonSubmitRequest = null) {
    this.endpoint = endpoint;
    this.inputOrderNumber = inputOrderNumber;
    this.buttonSubmitRequest = buttonSubmitRequest;
  }

  /**
   * Listens to track order form for submission, triggers the API request.
   */
  initListShipmentsApiRequest() {
    /* On click, prevent default and show loading. */
    this.buttonSubmitRequest.on( 'click', function(e) {
      e.preventDefault();

      /* Begin loading spinner. */
      $( '#loading-spinner' ).removeClass( 'hidden' );

      this.requestListShipments( this.inputOrderNumber.val() );
    }.bind( this )); // Bind the class object to keep context.,
  }

  /**
   * Makes the request to the ShipStation API.
   * @param {string} orderNumber - The order number pertinent to the API request.
   */
  requestListShipments( orderNumber ) {
    var self = this;

    /* Use AJAX to make a call to the PHP class for list shipments data without refreshing the page. */
    $.ajax({
      url: document.location.origin + '/wp/wp-admin/admin-ajax.php',
      type: 'POST',
      data: {
        action: 'shipstation_api_request',
        endpoint: self.endpoint,
        params: {
          orderNumber: orderNumber,
        },
        body: null,
      },
      error: function ( data ) {
        console.log( data );
      },
      success: function ( response ) {
        /* Return the successful AJAX JSON response from the ShipStation object. */
        /* Log the raw response collection. */
        console.log( response );

        /* Check response results. */
        var total = response['total'];

        /* If there is atleast 1 result, call to handle the response data. */
        if ( total > 0 ) {
          self.returnListShipments( response, orderNumber );
        }

        /* If there are no results, present "Order not found..."" message. */
        if ( total === 0 ) {
          console.log( 'No order found.' );
          $( 'div[data-narvar-feature=dynamicTrack]' ).html('Order not found. It is possible your order has not shipped yet. Please check your order number or try again later.');
        }

        /* Finally, hide the tracking form, show the dynamic track response, and hide the loading spinner. */
        $( '#track-order-form' ).addClass( 'hidden' );
        $( '#track-reload' ).removeClass( 'hidden' );
        $( '#loading-spinner' ).addClass( 'hidden' );
      },
    });
  }

  /**
   * Return the ShipStation API response data by parsing the required parameters into Narvar Dynamic Track embed code.
   * @param {array} response - The response data, returned as json/array.
   * @param {string} orderNumber - The input data representing the order number being tracked.
   */
   returnListShipments( response, orderNumber ) {
    // console.log(orderNumber);

    const results = response;
    // console.log(results['shipments'][0]['trackingNumber']);

    let trackingNumber = results['shipments'][0]['trackingNumber'];
    // console.log(trackingNumber);

    /**
     * Tap into the NARVAR Dynamic Track embed feature and set the parameter to display results.
     */
    window.narvar('dynamicTrack', {
      localeLanguage: 'en',
      localeCountry: 'US',
      trackingNumbers: trackingNumber,
      carrier: 'usps',
      orderNumbers: orderNumber,
    });
  }
  
  init() {
    /* Initialize request object for `List Shipments` endpoint. */
    if (this.endpoint === '/shipments') {
      this.initListShipmentsApiRequest();
    }

    /* Initialize request object for `Get Order` endpoint. */
    if (this.endpoint === '/orders') {
      // this.initGetOrderApiRequest();
    }
  }

}

export default ShipStation;
