
import AddToCart from './AddToCart';
import ViewMoreContent from './ViewMoreContent';
import Alert from './Alert';
// import { setTimeout } from 'timers';
import collect from 'collect.js';
import priceFilter from './../../filters/price';

export default {
  components: {
    AddToCart,
    ViewMoreContent,
    Alert,
  },

  props: {},

  data() {
    return {
      productId: null,
      product: {},
      submissionError: false,
    };
  },

  computed: {
    imageMessage() {
      if (!this.product) {
        return;
      }

      return this.product.image_message;
    },

    displayPrice() {
      if (!this.isVariable) {
        return priceFilter(this.product.price);
      }

      if (!this.hasVariationPriceRange) {
        return priceFilter(this.maximumVariationDisplayPrice);
      }

      let minPrice = priceFilter(this.minimumVariationDisplayPrice);
      let maxPrice = priceFilter(this.maximumVariationDisplayPrice);
      return `${minPrice} - ${maxPrice}`;
    },

    isVariable() {
      return this.product.type === 'variable';
    },

    productLoaded() {
      let product = typeof this.product === 'object' ? this.product : {};

      return Object.keys(product).length > 0;
    },

    variationPrices() {
      if (!this.variationAttributes.variations) {
        return collect({});
      }

      return collect(this.variationAttributes.variations).map(variation => {
        return {
          display_price: variation.display_price,
          display_regular_price: variation.display_regular_price,
        };
      });
    },

    minimumVariationDisplayPrice() {
      return this.variationPrices.min('display_price');
    },

    maximumVariationDisplayPrice() {
      return this.variationPrices.max('display_price');
    },

    hasVariationPriceRange() {
      return (
        this.minimumVariationDisplayPrice < this.maximumVariationDisplayPrice
      );
    },

    variationAttributes() {
      if (!this.productLoaded || !this.product.variation_attributes) {
        return {};
      }

      return this.product.variation_attributes;
    },

    sliderImages() {
      if (!this.productLoaded || !this.product.slider_images) {
        return {};
      }

      return this.product.slider_images;
    },
  },

  methods: {
    getProduct(productId) {
      this.productId = productId;
      this.product = {};

      return new Promise((resolve, reject) => {
        let data = {
          params: {
            action: 'mdgpe_quick_view_load_modal',
            product_id: this.productId,
          },
        };

        this.$http
          .get(this.$ajaxUrl, data)
          .then(({ data: { product } }) => resolve(product ? product : {}))
          .catch(error => reject(error));
      });
    },

    close() {
      this.$refs.modal.close();
    },

    open(productId) {
      this.productId = productId;

      this.$refs.modal.open();
      window.Bus.$emit('mdgqv.modal.opened', {
        productId: this.productId,
      });
    },

    handleClose() {
      let closedProductId = this.productId;
      this.productId = null;
      window.Bus.$emit('mdgqv.modal.closed', {
        productId: closedProductId,
      });
    },
  },

  watch: {
    productId(productId) {
      this.getProduct(productId).then(product => (this.product = product));
    },
  },

  mounted() {
    window.Bus.$on('mdgqv.modal.open', ({ productId }) => {
      this.open(productId);
    });

    window.Bus.$on('mdgqv.add-to-cart.failed', () => {
      this.submissionError = true;
    });

    window.Bus.$on('mdgqv.add-to-cart.submitting', () => {
      this.submissionError = false;
    });

    window.Bus.$on('mdgqv.add-to-cart.succeeded', () => {
      this.submissionError = false;
      this.close();
    });
  },
};
