let checkoutCheckboxStyles = {
  // WooCommerce doesn't use the same input + label hierarchy for its checkbox fields
  // Instead, it uses label < checkbox, and so since the ::before is attached to the label,
  // we have to travel back up the tree to change our pseudo-checkbox
  styleCheckboxes () {
    var checkbox = $('.billing-shipping input[type="checkbox"]');
    var label = $(checkbox).parent('label');
    $(label).on('click', function(e) {
      if (e.target.tagName.toLowerCase() == 'span') {
        $(e.target).closest(label).toggleClass('checked');
      } else {
        $(e.target).toggleClass('checked');
      }
    })
  },
}

export default function() {
  checkoutCheckboxStyles.styleCheckboxes();
}
