
export default {
  props: {
    dataProductId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      productId: this.dataProductId,
    };
  },

  computed: {},

  methods: {
    openModal() {
      window.Bus.$emit('mdgqv.modal.open', { productId: this.productId });
    },
  },
};
