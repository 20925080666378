
import Accordion from './Accordion.vue';

export default {
	name: 'personalize-personalized-embroidery-font',

	components: {
		Accordion,
	},

	props: {
		details: {
			type: Object,
			required: true,
		},
	},

  data() {
		return {
			activeId: 0,
			activeOnHatImageSrc: '',
		};
	},

	computed: {
		fonts() {
			if (typeof this.details.fonts === 'undefined') {
				return [];
			}

			return this.details.fonts;
		},
	},

	methods: {
		change() {
			this.$emit('change', this.activeId);
		},

    clear() {
			this.activeId = 0;
			this.activeOnHatImageSrc = '';
			this.change();
		},

		onFontClick(font) {
			this.activeId = font.id;
			this.activeOnHatImageSrc = font.onHatImageSrc;
			this.change();
		},
	},
};
