
import Alert from './Alert';

export default {
  components: {
    Alert,
  },

  data() {
    return {
      productTitle: undefined,
    };
  },

  mounted() {
    window.Bus.$on('mdgqv.add-to-cart.succeeded', ({ product }) => {
      this.productTitle = product.title;
    });
  },
};
