
import collect from 'collect.js';
export default {

    props: {
    dataImages: {
        type: [Object, Array],
        required: true,
    },

    dataIsVariable: {
        type: Boolean,
        default: false,
    },

    dataSliderConfiguration: {
        type: Object,
        default() {
        return {};
        },
    },

    dataCarouselConfiguration: {
        type: Object,
        default() {
        return {};
        },
    },

    dataMessage: {
        type: String,
        default: '',
    },
  },

  data() {
    return {
      selectedVariationId: undefined,
      selectedColor: undefined,
      shouldReset: false,
      isLoading: false,
      height: 'auto',
      activeIndex: 0,
      isTransitioning: false,
    };
  },

  computed: {
    images() {
      return this.dataImages;
    },

    isVariable() {
      return this.dataIsVariable;
    },

    hasSelection() {
      return this.selectedVariationId || this.selectedColor;
    },

    sliderImages() {
      if (!this.images || !this.images.variations) {
        return [];
      }

      return this.images.variations;
    },

    defaultImages() {
      if (!this.images || !this.images.default) {
        return [];
      }

      return this.images.default;
    },

    activeImage() {
      let activeImage = this.currentImages[this.activeIndex];

      if (!activeImage) {
        activeImage = this.defaultImages[this.activeIndex];
      }

      return activeImage.image;
    },

    zoomImage(){
         let activeImage = this.currentImages[this.activeIndex];

      if (!activeImage) {
        activeImage = this.defaultImages[this.activeIndex];
      }

      return activeImage.zoom;
    },

    currentImages() {
      if (!this.images) {
        return [];
      }

      if (!this.isVariable || !this.hasSelection || !this.sliderImages) {
        return this.defaultImages;
      }

      let images = collect(this.sliderImages);
      let matches = images.where('color', this.selectedColor);
      if (this.selectedVariationId) {
        matches = images.where('id', this.selectedVariationId);
      }

      let match = matches
        .map(image => collect(image).except(['color', 'id']))
        .first();

      if (!match || match.count() === 0) {
        return this.images.default;
      }

      return match.values().all();
    },

    carouselConfiguration() {
      return Object.assign(
        {
          slidesToShow: 5,
          slidesToScroll: 1,
          asNavFor: this.$refs['slider'],
          dots: false,
          focusOnSelect: true,
        },
        this.dataCarouselConfiguration
      );
    },

    sliderConfiguration() {
      return Object.assign(
        {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          fade: true,
          asNavFor: this.$refs['carousel'],
        },
        this.dataSliderConfiguration
      );
    },
  },

  methods: {
    handleVariationSelected() {
      window.Bus.$on('mdgpe.variation-selected', variation => {
        if (!variation) {
          this.selectedVariationId = undefined;
          return;
        }

        this.selectedVariationId = variation.get('variation_id');
      });
    },

    handleColorSelected() {
      window.Bus.$on('mdgpe.variation-color-selected', color => {
        this.selectedColor = color;
      });
    },

    slideTo(index) {
      this.height = `${this.$refs.wrapper.offsetHeight}px`;
      this.activeIndex = index;
      setTimeout(() => (this.height = 'auto'), 500);
    },
  },

  watch: {
    currentImages() {
      this.slideTo(0);
    },
  },

  mounted() {
    this.handleVariationSelected();
    this.handleColorSelected();
  },
};
