import { render, staticRenderFns } from "./Alert.vue?vue&type=template&id=52703d2c&"
import script from "./Alert.vue?vue&type=script&lang=js&"
export * from "./Alert.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/web/app/themes/ignite/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52703d2c')) {
      api.createRecord('52703d2c', component.options)
    } else {
      api.reload('52703d2c', component.options)
    }
    module.hot.accept("./Alert.vue?vue&type=template&id=52703d2c&", function () {
      api.rerender('52703d2c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "scripts/hat-customizer/components/Alert.vue"
export default component.exports