let utilities = {

  featuredContentBlock () {
    var videoModal = $('.featured-content-modal');
    var featuredText = $('.featured-content .text-wrap');

    if (videoModal.length) {
        featuredText.addClass('has-video');
    } else {
        featuredText.removeClass('has-video');
    }
  },

  relatedProductsClasses () {
    var rpList = '.related.products ul.products';
    var rpItems = '.related.products li.product';

    $(rpList).addClass('related-products-js swiper-container-js');
    $(rpItems).addClass('swiper-slide');
  },

}

export default function() {
  utilities.featuredContentBlock();
  utilities.relatedProductsClasses();
}
