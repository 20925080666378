
import AttributeSelect from './AttributeSelect';
import AttributeBandColorSelect from './AttributeBandColorSelect';
import collect from 'collect.js';
import ColorSwatch from './ColorSwatch';
import { setTimeout } from 'timers';
import VariationPrice from './VariationPrice';

export default {
  components: {
    'attribute-select': AttributeSelect,
    'attribute-band-color-select': AttributeBandColorSelect,
    'color-swatch': ColorSwatch,
    'variation-price': VariationPrice,
  },

  props: {
    dataBandColorAttributeName: {
      type: String,
      required: true,
    },

    dataBandColorEnabled: {
      type: Boolean,
      default: true,
    },

    dataColorAttributeName: {
      type: String,
      required: true,
    },

    dataColorEnabled: {
      type: Boolean,
      default: true,
    },

    dataDefaultAttributes: {
      type: [Object, Array],
      default() {
        return {};
      },
    },

    dataSizeAttributeName: {
      type: String,
      required: true,
    },

    dataSizeEnabled: {
      type: Boolean,
      default: true,
    },

    dataVariationLowStockAmounts: {
      type: [Object, Array],
      default() {
        return {};
      },
    },

    dataVariations: {
      type: [Object, Array],
      required: true,
    },

    dataVerticalLayout: {
      type: Boolean,
      default: true,
    },

  },

  data() {
    return {
      bandColorAttributeName: this.dataBandColorAttributeName,
      bandColorValue: undefined,
      colorAttributeName: this.dataColorAttributeName,
      colorValue: undefined,
      defaultAttributes: this.dataDefaultAttributes,
      selectedVariation: undefined,
      sizeAttributeName: this.dataSizeAttributeName,
      sizeValue: undefined,
      variationLowStockAmounts: this.dataVariationLowStockAmounts,
      variations: this.collectVariations(this.dataVariations),
    };
  },

  computed: {
    bandColorEnabled() {
      return (
        this.bandColorAttributeName &&
        this.dataBandColorEnabled &&
        this.bandColors.isNotEmpty()
      );
    },

    bandColors() {
      return this.getAttributeValues(this.bandColorAttributeName);
    },

    colorEnabled() {
      return (
        this.colorAttributeName &&
        this.dataColorEnabled &&
        this.colors.isNotEmpty()
      );
    },

    colors() {
      return this.getAttributeValues(this.colorAttributeName);
    },

    sizeEnabled() {
      return (
        this.sizeAttributeName &&
        this.dataSizeEnabled &&
        this.sizes.isNotEmpty()
      );
    },

    sizes() {
      return this.getAttributeValues(this.sizeAttributeName);
    },

    unselectedAttributes() {
      let matches = collect([]);

      if (this.colorValue) {
        this.unselectedAttributesForColor.each(attribute => {
          return matches.push(attribute);
        });
      }

      if (this.sizeValue) {
        this.unselectedAttributesForSize.each(attribute => {
          return matches.push(attribute);
        });
      }

      if (this.bandColorValue) {
        this.unselectedAttributesForBandColor.each(attribute => {
          return matches.push(attribute);
        });
      }

      return matches;
    },

    unselectedAttributesForColor() {
      return this.variations
        .filter(variation => this.variationColorMatched(variation))
        .map(variation => {
          let attribute = variation.get('attributes')[this.sizeAttributeName, this.bandColorAttributeName];
          if (!attribute) {
            return;
          }

          attribute.variation = variation;
          return attribute;
        })
        .filter();
    },

    unselectedAttributesForSize() {
      return this.variations
        .filter(variation => this.variationSizeMatched(variation))
        .map(variation => {
          let attribute = variation.get('attributes')[this.colorAttributeName, this.bandColorAttributeName];
          if (!attribute) {
            return;
          }

          attribute.variation = variation;
          return attribute;
        })
        .filter();
    },

    unselectedAttributesForBandColor() {
      return this.variations
        .filter(variation => this.variationBandColorMatched(variation))
        .map(variation => {
          let attribute = variation.get('attributes')[this.colorAttributeName, this.sizeAttributeName];
          if (!attribute) {
            return;
          }

          attribute.variation = variation;
          return attribute;
        })
        .filter();
    },
},

  methods: {
    /*******************************************
      Seters and Getters for data and computed
    *******************************************/

    /**
     * Set the data.variations value.
     */
    collectVariations(variations) {
      return collect(variations).map(variation => {
        if (typeof variation === 'object') {
          variation = collect(variation);
        }

        return variation;
      });
    },

    /**
     * Set the computed colors and sizes attributes.
     */
    getAttributeValues(name) {
      return this.variations
        .map(variation => {
          if (!variation.get('attributes', {})[name]) {
            return {};
          }

          return variation.get('attributes', {})[name];
        })
        .filter(variation => typeof variation.id !== 'undefined')
        .unique('id');
    },

    attributeMatched(variation, attributeName, value) {
      if (!value) {
        return true;
      }

      let attribute = variation.get('attributes', {})[attributeName];

      if (!attribute) {
        return false;
      }

      return attribute.slug === value;
    },

    variationColorMatched(variation) {
      let attributeName = this.colorAttributeName;
      let value = this.colorValue;

      return this.attributeMatched(variation, attributeName, value);
    },

    variationSizeMatched(variation) {
      let attributeName = this.sizeAttributeName;
      let value = this.sizeValue;

      return this.attributeMatched(variation, attributeName, value);
    },

    variationBandColorMatched(variation) {
      let attributeName = this.bandColorAttributeName;
      let value = this.bandColorValue;

      return this.attributeMatched(variation, attributeName, value);
    },

    variationAllMatched(variation) {
      return (
        this.variationColorMatched(variation) &&
        this.variationSizeMatched(variation) &&
        this.variationBandColorMatched(variation)
      );
    },

    /*******************
      Reactive Methods
    ********************/

    /**
     * Gets the selected variation when a swatch color is clicked.
     */
    getSelectedVariation() {
      if (this.defaultAttributes !== 'undefined') {
        var bandColor = 'attribute_pa_band-color';
        if (this.defaultAttributes[bandColor]  && !this.bandColorValue) {
          return;
        }
        if (this.defaultAttributes.attribute_pa_color && !this.colorValue) {
          return;
        }
        if (this.defaultAttributes.attribute_pa_size && !this.sizeValue) {
          return;
        }
      }

      let match = this.variations
        .filter(variation => this.variationAllMatched(variation))
        .first();

      if (typeof match === 'undefined') {
        return;
      }

      return match['items'];
    },

    /**
     * Persists the selected variation data to and from Product Enhancements and Hat Customizer.
     */
    persistSelectedVariation() {
      /* Access the {selectedVariation} data on the HatCustomizer component (as mdghcCustomizer $ref). */
      let persistSelectedVariationObj = this.$root.$refs.mdghcCustomizer.selectedVariation; // Dot notation method

      if ( typeof persistSelectedVariationObj !== 'undefined' ) {

        let persistColorValue = persistSelectedVariationObj.attributes[this.colorAttributeName]; // Dot notation method
        if ( typeof persistColorValue !== 'undefined' ) {
          this.colorValue = persistColorValue.slug;
        }

        let persistSizeValue = persistSelectedVariationObj.attributes[this.sizeAttributeName]; // Dot notation method
        if ( typeof persistSizeValue !== 'undefined' ) {
          this.sizeValue = persistSizeValue.slug;
        }

      }

      return (this.colorValue && this.sizeValue);
    },

    /**
     * Get the default color and size attribute values from WooCommerce.
     * @param {string} name The name of the attribute slug.
     */
    getDefaultValue(name) {
      if (this.defaultAttributes[name]) {
        return this.defaultAttributes[name];
      }
    },

    /**
     * Set the default color and size attribute values for this instance.
     */
    setDefaultValues() {
      this.colorValue = this.getDefaultValue(this.colorAttributeName);
      this.sizeValue = this.getDefaultValue(this.sizeAttributeName);
      this.bandColorValue = this.getDefaultValue(this.bandColorAttributeName);

      return (this.colorValue && this.sizeValue);
    },

    /**********************
      WooCommerce Methods
    **********************/

    getWooCommereceSelectSelector(attributeName) {
      let termName = attributeName.replace('attribute_', '');
      return `select#${termName}`;
    },

    setWooCommerceSelectValue(attributeName, value) {
      let selector = this.getWooCommereceSelectSelector(attributeName);
      let $select = document.querySelector(selector);

      if (!$select) {
        return;
      }

      $select.value = value;

      /**
       * Let other plugins know the variation has changed with the same event listener WooCommerce uses.
       * Let YITH WCWL (Waitlist) know that the variation has changed with its own event listener.
       */
      window
        .jQuery($select.form)
        .trigger('woocommerce_variation_select_change')
        .trigger('yith_wcwl_reload_fragments');
    },

    hideWooCommerceAttributeRow(attributeName) {
      let selector = this.getWooCommereceSelectSelector(attributeName);
      let $select = document.querySelector(selector);

      if (!$select) {
        return;
      }

      $select.parentElement.parentElement.classList.add('mdgpe-hidden');
    },

    setWooCommerceAttributeDisplay() {
      if (this.colorEnabled) {
        this.hideWooCommerceAttributeRow(this.colorAttributeName);
      }

      if (this.sizeEnabled) {
        this.hideWooCommerceAttributeRow(this.sizeAttributeName);
      }

      if (this.bandColorEnabled) {
        this.hideWooCommerceAttributeRow(this.bandColorAttributeName);
      }

      document.body.className = document.body.className.replace(
        'mdgpe-loading',
        ''
      );
    },

    handleWooCommerceSelectChange($select, dataKey) {
      this.$nextTick(() => {
        let value = $select.value;

        if (value === this[dataKey]) {
          return;
        }

        this[dataKey] = value;
      });
    },

    watchWooCommerceSelectChange(attributeName, dataKey) {
      let selector = this.getWooCommereceSelectSelector(attributeName);
      let $select = document.querySelector(selector);

      if (!$select) {
        return;
      }

      let $form = $select.form;
      if ($form && window.jQuery) {
        window.jQuery($form).on('woocommerce_variation_select_change', () => {
          this.handleWooCommerceSelectChange($select, dataKey);
        });
      }

      $select.addEventListener('change', () => {
        this.handleWooCommerceSelectChange($select, dataKey);
      });
    },

    /*******************
      DOM Manipulation
    *******************/

    /**
     * Show or hide DOM elements based on stock status.
     * @param {number} variationId
     */
    showHideStockElements(variationId) {
      let lowStockAmount = this.variationLowStockAmounts[variationId];
      let quantity = this.selectedVariation.max_qty;
      let isInStock = this.selectedVariation.is_in_stock;

      if (isInStock) {
        this.setStockClass('in-stock');
        this.setQuantityMaximum(quantity);

        // Visually hide and disable the Add to Cart and Quantity elements
        $('.woocommerce-variation-add-to-cart').css('display', 'flex');
        $('.single_add_to_cart_button').prop('disabled', false);
        $('.single_add_to_cart_button').removeClass('disabled wc-variation-is-unavailable');
        $('.wcwl_elements').css('display', 'none');

        if (quantity > lowStockAmount) {
          this.setStockMessage('In stock');
        } else {
          this.setStockMessage('Only ' + quantity + ' remaining!');
        }
      } else {
        this.setStockClass('out-of-stock');
        this.setStockMessage('Out of stock');

        // Visually hide and disable the Add to Cart and Quantity elements for out-of-stock variations
        $('.woocommerce-variation-add-to-cart').css('display', 'none');
        $('.single_add_to_cart_button').prop('disabled', true);
        $('.single_add_to_cart_button').addClass('disabled wc-variation-is-unavailable');
        $('.wcwl_elements').css('display', 'block');
      }
    },

    /**
     * Updates the HTML of the element that shows the stock to the user.
     *
     * @param {string} message The message to show for the stock.
     */
    setStockMessage(message) {
      $('.woocommerce-variation-availability .stock').html(message);
    },

    /**
     * Updates the CSS class of the element that shows the stock to the user.
     *
     * @param {string} stockClass The indicator class to add to the stock element (in-stock, out-of-stock)
     */
    setStockClass(stockClass) {
      $('.woocommerce-variation-availability .stock').removeClass().addClass('stock ' + stockClass);
    },

    /**
     * Sets the quantity input's maximum to the number of items in stock for that variation
     *
     * @param {number} quantity The quantity of that variation
     */
    setQuantityMaximum(quantity) {
      $('.quantity input').attr('max', quantity);
    },

    /**
     * Updates WooCommerce `variation_id` input when user interacts with the AttributeSelect component.
     *
     * @param {number} variationId The variation id emitted by `mdgpe.variation-selected`.
     */
    updateVariationId(variationId) {
      let $input = $('input[name="variation_id"]');
      if ($input.length === 0) {
        return;
      }
      $('input[name="variation_id"]').val(variationId);
    },

    /***************************
      Watcher and Emit Methods
    ***************************/

    watchExternalEvents() {
      this.watchWooCommerceSelectChange(this.colorAttributeName, 'colorValue');
      this.watchWooCommerceSelectChange(this.sizeAttributeName, 'sizeValue');
      this.watchWooCommerceSelectChange(this.bandColorAttributeName, 'bandColorValue');
    },

    emitColorSelected() {
      window.Bus.$emit('mdgpe.variation-color-selected', this.colorValue);
    },

    emitSizeSelected() {
      window.Bus.$emit('mdgpe.variation-size-selected', this.sizeValue);
    },

    emitBandColorSelected() {
      window.Bus.$emit('mdgpe.variation-band-color-selected', this.bandColorValue);
    },

    /**
     * Emit the product variation that was selected.
     *
     * @fires ProductVariationAttributeSelectors#mdgpe.variation-selected
     */
    emitVariationSelected() {
      window.Bus.$emit('mdgpe.variation-selected', this.selectedVariation);
        if(this.selectedVariation !== undefined) {
            let variationId = this.selectedVariation.variation_id;
            this.updateVariationId(variationId);
            this.showHideStockElements(variationId);
        }
    },

  },

  watch: {
    colorValue(newValue) {
      this.setWooCommerceSelectValue(this.colorAttributeName, newValue);
      this.selectedVariation = this.getSelectedVariation();
      this.$nextTick(this.emitColorSelected);
    },

    sizeValue(newValue) {
      this.setWooCommerceSelectValue(this.sizeAttributeName, newValue);
      this.selectedVariation = this.getSelectedVariation();
      this.$nextTick(this.emitSizeSelected);
    },

    bandColorValue(newValue) {
      this.setWooCommerceSelectValue(this.bandColorAttributeName, newValue);
      this.selectedVariation = this.getSelectedVariation();
      this.$nextTick(this.emitBandColorSelected);
    },

    selectedVariation() {
      this.$nextTick(this.emitVariationSelected);
    },
  },

  mounted() {
    /* When mounting the ProductEnhancements component, check to see if there is any data to persist. */
    this.persistSelectedVariation();

    /* If HatCustomizer emits 'mdghc.selected-variation-updated', persist the data again. */
    window.Bus.$on('mdghc.selected-variation-updated', () => {
      this.persistSelectedVariation();
    });

    /* If after each check to persist we still don't have a colorValue or sizeValue, get the WooCommerce defaults. */
    if ( !this.colorValue && !this.sizeValue ) {
      this.setDefaultValues();
    }

    this.watchExternalEvents();
    this.setWooCommerceAttributeDisplay();
    this.selectedVariation = this.getSelectedVariation();

    setTimeout(this.emitVariationSelected, 300);
  },
};
