import { render, staticRenderFns } from "./Logos.vue?vue&type=template&id=e938e516&"
import script from "./Logos.vue?vue&type=script&lang=js&"
export * from "./Logos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/web/app/themes/ignite/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e938e516')) {
      api.createRecord('e938e516', component.options)
    } else {
      api.reload('e938e516', component.options)
    }
    module.hot.accept("./Logos.vue?vue&type=template&id=e938e516&", function () {
      api.rerender('e938e516', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "scripts/hat-customizer/components/AddLogo/Logos.vue"
export default component.exports