// Create a new Navigation object
class SidebarFilters {
  // Initialize object properties
  constructor() {
    this.openBtn = $( '.filters-open-btn' );
    this.closeBtn = $( '.filters-close-btn' );
    this.showResultsBtn = $( '.filters-show-btn' );
    this.clearBtn = $( '.filters-clear-btn' );
    this.sidebar = $( '.sidebar-filters' );
    this.widgets = $( '.filters-widgets' );
    this.header = $( '.main-header' );
    this.page = $( 'body' );
    this.closeTriggers = [this.closeBtn, this.showResultsBtn];
    this.attrs = $( '.woocommerce-widget-layered-nav-list li a');
  }

  /**
   * Portable function that contains logic to open mobile sidebar
   */
  openSidebar() {
    // Initiate slide up animation
    this.sidebar.addClass( 'mobile-show' ).addClass( 'mobile-slide-up' );
    // Prevent body scroll
    this.page.addClass( 'no-scroll' );

    // Reset dropdown height calculations
    this.getDropdownHeights();
    // Sets mobile dropdowns to closed by default
    this.widgets.find( '.widget' ).each( function() {
      $( this ).removeClass( 'widget-open' );
    });

    // Reset position in relation to site header
    this.positionSidebar();


    // End slide up animation after delay
    setTimeout(function() {
      this.sidebar.removeClass( 'mobile-slide-up' );
    }.bind(this), 750); // Bind the class object to keep context
  }

  /**
   * Portable function that contains logic to close mobile sidebar
   */
  closeSidebar() {
    // Initiate slide down animation
    this.sidebar.removeClass( 'mobile-show' ).addClass( 'mobile-slide-down' );
    // Reset dropdown height calculations
     this.getDropdownHeights();
    // Sets desktop dropdowns to open by default
    this.widgets.find( '.widget' ).each( function() {
      $( this ).addClass( 'widget-open' );
    });
    // Re-enable body scroll
    this.page.removeClass( 'no-scroll' );

    // End slide down animation after delay
    setTimeout(function() {
      this.sidebar.removeClass( 'mobile-slide-down' );
    }.bind(this), 750); // Bind the class object to keep context
  }

  /**
   * Initialize the open filters button for mobile
   */
  initOpenTrigger() {
    this.openBtn.on( 'click', function(e) {
      e.preventDefault();
      this.openSidebar();
    }.bind(this)); // Bind the class object to keep context
  }

  /**
   * Initialize the close and show results buttons for mobile
   */
  initCloseTriggers() {
    // Apply listeners to both close and show results buttons
    $.each( this.closeTriggers, function( i, obj ) {
      $( obj ).on( 'click', function( e ) {
        e.preventDefault();
        this.closeSidebar();
      }.bind( this )); // Bind the class object to keep context

    }.bind( this )); // Bind the class object to keep context
  }

  /**
   * Portable function that contains logic to calculate widget dropdown heights depending on contents
   */
  getDropdownHeights() {
    var itemHeight = 0;
    var widgetHeight = 0;

    // Iterate through all .widget-content instances
    this.widgets.find( '.widget .widget-content' ).each( function() {
      // Iterate through all children elements
      $( this ).children().each( function() {
        // Get each individual child element's height
        itemHeight = $( this ).outerHeight( true );

        // Add each child element's height to the widget's total height
        widgetHeight = parseFloat( widgetHeight ) + parseFloat( itemHeight );

        // Reset the child element's height for next iteration
        itemHeight = 0;
      });
      // Pass the calculated widget height to a data-* attribute
      $( this ).css( '--calculated-height', widgetHeight + 'px' );
      // Reset widget height for next iteration
      widgetHeight = 0;
    });
  }

  /**
   * Initialize the widget dropdowns to open by default
   */
  initToggleDropdowns() {
    // Adds initial dropdown height check to dropdown init function
    this.getDropdownHeights();

    // Find all widgets
    this.widgets.find( '.widget' ).each( function(i, widget) {
      // Find all widget headers (h3)
      $( this ).find( 'h3' ).each( function(i, heading) {
        // Apply click listener to h3
        $( heading ).on( 'click', function( e ) {
          e.preventDefault();
          // Sets dropdowns to open by default
          $( widget ).toggleClass( 'widget-open' );
        });
      });
    });
  }

  /**
   * Count the number of selected filters & append to the relevant elements
   */
  countFilters() {
    var totalFilters = 0;
    var groupFilters = 0;

    // Find all instances of .widget
    this.widgets.find( '.widget' ).each( function() {
      // Find all selected filters within the parent widget
      $( this ).find( '.chosen' ).each( function() {
        // Increase the count of both the total filters
        // and the filters for just the current parent widget
        totalFilters++;
        groupFilters++;
      });

      // Append the widget's number of selected filters to the widget header
      // if at least one filter is selected
      if (groupFilters >= 1) {
        $( this ).children( 'h3' ).append(' (' + groupFilters + ')');
      }

      // Reset individual widget filters count for next iteration
      groupFilters = 0;
    });

    // Append the total number of selected filters to the clear button
    // if at least one filter is selected
    if (totalFilters >= 1) {
      this.clearBtn.append(' (' + totalFilters + ')');
    } else {
      // If no filters selected, disable the button
      this.clearBtn.addClass('disabled');
    }

    // Reset total filter count to keep function portable
    totalFilters = 0;
  }

  /**
   * Position (absolute) the mobile filters in relation to the primary site header
   */
   positionSidebar() {
    var topPosition = 0;
    var headerHeight = this.header.outerHeight( true );
    var adminBar = 0;

    if (this.page.hasClass( 'admin-bar' )) {
      adminBar = $( '#wpadminbar' ).outerHeight( true );
    }

    topPosition = parseFloat( headerHeight ) + parseFloat( adminBar );

    this.sidebar.css( '--calculated-top', topPosition + 'px' );
  }

  /**
   * Handle page resize callback
   */
  handleResize() {
    if (this.sidebar.hasClass( 'mobile-show' )) {
      this.closeSidebar();
    }
  }

  /**
   * Apply color swatches
   */
  colorSwatches() {
    $.each(this.attrs, function (i, item) {
      const checkboxColor = item.dataset.checkboxColor;
      const checkboxImage = item.dataset.checkboxImage;
      if (checkboxColor) {
        // Set CSS property to value from data property
        item.style.setProperty('--checkbox-color', checkboxColor);

        // Set border color
        if (checkboxColor === '#ffffff') {
          item.style.setProperty('--border-color', '#bbbbbb');
        } else {
          item.style.setProperty('--border-color', checkboxColor);
        }
      }

      if (checkboxImage) {
        item.style.setProperty('--checkbox-image', 'url("' + checkboxImage + '")');
        item.style.setProperty('--border-color', '#bbbbbb');
      }
    });
  }

  /**
   * Method callback to initialize the Navigation object
   */
  init() {
    // Add necessary classes to woocommerce elements
    $( '.widget h3' ).siblings().addClass( 'widget-content' );
    $( '.widget' ).addClass( 'widget-open' );

    this.colorSwatches();
    this.countFilters();
    this.initOpenTrigger();
    this.initCloseTriggers();
    this.initToggleDropdowns();

    // Add window resize listener with debounce
    $( window ).on( 'resize', function() {
      setTimeout( this.handleResize(), 1000 );
    }.bind( this )); // Bind the class object to keep context
  }
}

// Export class as default
export default SidebarFilters;
