export default function(price) {
    if (!price) {
        return '';
    }

    price = parseFloat(price, 10);
    price = isNaN(price) ? 0 : price;

    return (price = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    }).format(price.toFixed(2)));
}
