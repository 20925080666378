
  import algoliasearch from 'algoliasearch';

  export default {
    name: 'instant-search',
    props: {
      appId: {
        type: String,
        required: true,
      },
      indexName: {
        type: String,
        required: true,
      },
      searchKey: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        searchClient: algoliasearch(this.appId, this.searchKey),
        isOpen: false,
      };
    },
    mounted() {
      const toggle = document.querySelectorAll('.search-modal-toggle');
      toggle.forEach((el) => {
        el.addEventListener('click', e => {
          e.preventDefault();
          this.toggleModal('open');
        });
      });
    },
    methods: {
      toggleModal: function (state) {
        const toggle = document.querySelectorAll('.search-modal-toggle');
        if (state === 'close') {
          this.isOpen = false;
          document.querySelector('#algolia-search').classList.remove('open');
          toggle.forEach((el) => {
            el.classList.remove('search-open');
          });
          document.querySelector('body').classList.remove('no-scroll');
        }
        if (state === 'open') {
          this.isOpen = true;
          document.querySelector('#algolia-search').classList.add('open');
          toggle.forEach((el) => {
            el.classList.add('search-open');
          });
          document.querySelector('body').classList.add('no-scroll');
        }
      },
    },
  };
