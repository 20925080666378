import checkoutOptions from '../components/checkout-options';
import checkoutCheckboxStyles from '../components/checkout-checkbox-styles';
import Precisely from '../precisely/address-verification';

export default {
  init() {
    // JavaScript to be fired on the checkout page

    /**
     * Initialilze checkout checkbox field styles
     */
    checkoutCheckboxStyles();

    /**
     * Initialilze Order Notes and Gift Option Showing/Hiding
     */
    checkoutOptions();

    /**
     * Construct and initialize the Precisely object.
     */
    const precisely = new Precisely(

      /* The billing address form elements from WooCommerce checkout. */
      this.billing = {
        AddressLine1:$( '#billing_address_1' ),
        AddressLine2:$( '#billing_address_2' ),
        City:$( '#billing_city' ),
        Country:$( '#billing_country' ),
        StateProvince:$( '#billing_state' ),
        PostalCode:$( '#billing_postcode' ),
      },

      /* The div element that will be used to show any validation errors for the billing address. */
      this.billingVerificationResponse = $( '.billing-address-verification-response' ),

      /* The checkbox element to choose which address to use from WooCommerce checkout. */
      this.useShippingAddress = $( '#ship-to-different-address-checkbox' ),

      /* The shipping address form elements from WooCommerce checkout. */
      this.shipping = {
        AddressLine1:$( '#shipping_address_1' ),
        AddressLine2:$( '#shipping_address_2' ),
        City:$( '#shipping_city' ),
        Country:$( '#shipping_country' ),
        StateProvince:$( '#shipping_state' ),
        PostalCode:$( '#shipping_postcode' ),
      },

      /* The div element that will be used to show any validation errors for the shipping address. */
      this.shippingVerificationResponse = $( '.shipping-address-verification-response' ),

      /* The WooCommerce submit button element */
      this.checkoutButton = '#place_order'

    );

    precisely.init();

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
