
export default {
  props: {
    value: {},

    dataName: {
      type: String,
      required: true,
    },

    dataShowNone: {
      type: Boolean,
      default: true,
    },

    dataOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedValue: this.value,
    };
  },

  computed: {
    showNone() {
      return this.dataShowNone;
    },

    term() {
      return this.name.replace('attribute_', '');
    },

    name() {
      return this.dataName;
    },

    options() {
      return this.dataOptions;
    },
  },

  methods: {
    emitInput() {
      this.$nextTick(() => this.$emit('input', this.$refs['select'].value));
    },
  },

  watch: {
    value(value) {
      this.selectedValue = value;
    },
  },
};
