
import Accordion from './Personalize/Accordion.vue';
import EmbroideryFont from './Personalize/EmbroideryFont.vue';
import PersonalizedText from './Personalize/PersonalizedText.vue';
import ThreadColor from './Personalize/ThreadColor.vue';

export default {
  name: 'personalize',

  components: {
    Accordion,
    EmbroideryFont,
    PersonalizedText,
    ThreadColor,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      personalizedText: '',
      threadColorId: 0,
      embroideryFontId: 0,
      details: {},
      restrictionsTextOpen: true,
    };
  },

  computed: {
    restrictionsText() {
      if (typeof this.details.restrictionsText === 'string') {
        return this.details.restrictionsText;
      }

      return '';
    },

    customizations() {
      return {
        personalizedText: this.personalizedText,
        threadColorId: this.threadColorId,
        embroideryFontId: this.embroideryFontId,
      };
    },
  },

  methods: {
    onPersonalizedTextChange(personalizedText) {
      this.personalizedText = personalizedText;
      this.change();
    },
    onThreadColorChange(id) {
      this.threadColorId = id;
      this.change();
    },
    onEmbroideryFontChange(id) {
      this.embroideryFontId = id;
      this.change();
    },
    change() {
      this.$emit('change', this.customizations);
    },

    setDetails(callback) {
      let options = {
        params: {
          action: 'hat_customizer_personalization_details',
        },
      };

      this.$http.get('/', options).then(response => {
        var details =
          typeof response.data.data.details === 'undefined'
            ? []
            : response.data.data.details;

        callback(details);
      });
    },
  },

  mounted() {
    let vm = this;

    vm.change();

    this.setDetails(function(details) {
      vm.details = details;
    });
  },
};
