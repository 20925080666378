
import collect from 'collect.js';

export default {
  props: {
    dataFilters: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    filters() {
      return this.dataFilters;
    },

    selectedOptions() {
      return collect(this.filters)
        .pluck('options')
        .flatten(1)
        .whereIn('value', this.selectedValues);
    },

    selectedValues() {
      return collect(this.filters)
        .pluck('values')
        .flatten()
        .all();
    },
  },

  methods: {},
};
