/**
 * Import Swiper from the node modules
 * Looking for where the CSS is imported? Check main.scss!
 * @see https://swiperjs.com/get-started
 */
import Swiper from 'swiper';

/**
 * Import the Navigation and Pagination modules for this specific slider
 * and configure Swiper to use those modules
 */

import SwiperCore, { Navigation, Scrollbar, Keyboard, A11y } from 'swiper/core';
SwiperCore.use([ Navigation, Scrollbar, Keyboard, A11y ]);

class SwiperConfig {
  /**
   * Set up the element(s) for the Swiper instance
   */
  constructor() {
    this.featuredProductCards = '.featured-product-cards-js';
    this.featuredCustomCards = '.featured-custom-cards-js';
    this.relatedProducts = '.related-products-js';
    this.nextEl = '.swiper-button-next';
    this.prevEl = '.swiper-button-prev';
    this.scrollbar = '.swiper-scrollbar';
    this.swiperOptions = {
       enabled: false,
       init: false,
       centerInsufficientSlides: true,
       edgeSwipeDetection: true,
       grabCursor: true,
       noSwipingSelector: 'button, a',
       observer: true,
       observeParents: true,
       resistanceRatio: 0.5,
       slideToClickedSlide: true,
       slidesPerView: 'auto',
       spaceBetween: 0,

       /**
        * Modules
        */
       a11y: {
         enabled: true,
       },
       keyboard: {
         enabled: true,
       },
       navigation: {
         prevEl: this.prevEl,
         nextEl: this.nextEl,
       },
       scrollbar: {
         el: this.scrollbar,
         draggable: true,
         hide: true,
       },

       /**
        * Events
        */
       on: {
         init: function () {
           /**
            * Checks if there are enough elements to swipe on init
            * If there are, enable swiper modules
            */
           if (!this.isEnd) {
             this.enable();
           }
         },
         resize: function () {
           /**
            * Checks if there are enough elements to swipe on resize & if is mobile
            * If there aren't or it is mobile, disable swiper modules
            * Else, enable swiper modules
            */
           if ((this.isBeginning && this.isEnd)) {
             this.disable();
           } else {
             this.enable();
           }
          },
        },
    };
  }

  /**
   * Initialize the Swiper instance and its settings
   * @see https://swiperjs.com/swiper-api
   */
  init() {
    const swiperCustomCards = new Swiper(this.featuredCustomCards, this.swiperOptions);
    const swiperRelatedProducts = new Swiper(this.relatedProducts, {
      ...this.swiperOptions,
      centerInsufficientSlides: false,
      // creates swiper-wrapper, scrollbar, and navigation buttons dynamically
      createElements: true,
      navigation: true,
      scrollbar: {
        auto: true,
        draggable: true,
        hide: true,
      },
    });

    // initialize multiple featuredProductCards sliders
    document.querySelectorAll(this.featuredProductCards).forEach((element) => {
      const swiperProductCards = new Swiper(element, this.swiperOptions);
      swiperProductCards.init();
    });

    swiperCustomCards.init();
    swiperRelatedProducts.init();
  }
}

export default SwiperConfig;
