/**
 * Narvar Requester
 *
 * @class Narvar
 */

const narvarConfig = require('../../../../app/Narvar/config/narvar-config.json');

class Narvar {

  /**
   * Initialize the Narvar requester object with all of its parameters.
   * @param {Object} shippingData - An array object collection of the product single custom form shipping data inputs.
   */
  constructor(shippingData) {
    this.endpoint = '/api/v2/eddCheckout';
    this.shippingData = shippingData;
  }

  /**
   * Get the hidden Narvar EDD formatted text and add it to the DOM.
   */
  initGetSetDisplay() {
    /* Initialize a variable with the hidden Narvar EDD result on Cart and Checkout pages. */
    var narvarEDD = $( '#narvar-edd-formatted' ).html();

    /* Set the default text for the Narvar EDD display element. */
    $( '#narvar-edd-display' ).html( narvarEDD );

    return;
  }

  /**
   * Slide toggle the Narvar EDD country and zip form on product single.
   */
  initFormSlideToggle() {
    $( '.narvar-edd-button' ).on( 'click', function() {
      $( '.narvar-edd-form' ).slideToggle( 'slow' );
      return false;
    });
  }

  /**
   * Listens to shipping address form on product single for submission, triggers the API request.
   */
  initRequestListener() {
    $( '#narvar-edd-request' ).on( 'click', function() {
      this.requestEDD( this.shippingData );
    }.bind( this )); // Bind the class object to keep context.,
  }

  /**
   * Makes the rewquest to the Narvar EDD API.
   * @param {Object} shippingData - A collection of shipping elements pertinent to the API request.
   */
  requestEDD( shippingData ) {
    var self = this;

    /* Access the shipping data elements, get their bvalues, and store them into a JSON formatted collection. */
    var shippingDataCollection = {
      'order_date':shippingData.order_date.val(),
      'dest_zip':shippingData.dest_zip.val(),
      'dest_country':shippingData.dest_country.val(),
      'dc_id':shippingData.dc_id.val(),
      'carrier_code':shippingData.carrier_code.val(),
      'service_code':shippingData.service_code.val(),
    };

    /* Log the request data collection. */
    console.log( shippingDataCollection );

    /* Use AJAX to make a call to the PHP class for estimated delivery date without refreshing the page. */
    $.ajax({
      url: document.location.origin + '/wp/wp-admin/admin-ajax.php',
      type: 'POST',
      data: {
        action: 'narvar_api_request',
        endpoint: self.endpoint,
        body: shippingDataCollection,
      },
      error: function ( data ) {
        console.log( data );
      },
      success: function ( response ) {
        /* Return the successful AJAX JSON response from the Narvar object. */
        /* Log the raw response collection. */
        console.log( response );

        /* Check response status. */
        var responseStatus = response['status'];

        if ( responseStatus === 'SUCCESS' ) {
          self.handleAPIResponse( response, shippingData.dest_country.val(), shippingData.carrier_code.val(), shippingData.service_code.val() );
        }

        if ( responseStatus === 'FAILURE' ) {
          /* Display the response failure message (string). */
          var responseMessage = response['message'];
          console.log( responseMessage );

          /**
           * @todo Add validation to the form fields.
           */
        }

      },
    });
  }

  /**
   * @todo Set the `WC()->session->customer['shipping_postcode']` and
   *       `WC()->session->customer['shipping_country']` variables to carry over into the cart page.
   */
  handleAPIResponse( response, destinationCountry, carrierCode, serviceCode ) {
    const results = response['edd_details'];

    /* Array object containing all the shipping carriers and services defined in WooCommerce and Narvar (Stored in JSON config file). */
    const shippingMethods = narvarConfig['ShippingMethods'];

    var date_edd = null;
    var date_edd_arr = [];
    var date_edd_furthest = null;
    var date_edd_furthest_arr = [];
    var date_edd_matched = null;
    var date_edd_matched_arr = [];
    var date_edd_displayed = null;

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th', '29th', '30th', '31st'];

    /* Loop through the Narvar Ship EDD results. */
    $.each( results, function ( key, value ) {
      /* Make sure there's an EDD end date key in the response. */
      if ( 'edd_end' in value ) {

        /* Use the destination country to get only the shipping methods available in that country. */
        /* First use the known service code to search for a matching array set inside the shipping methods array. */
        $.each( shippingMethods, function( thisKey, thisValue ) {
          if ( value['service_code'] === thisValue['service_code'] ) {

            /* Loop through the serviceable countries for that array entry. */
            $.each( shippingMethods[thisKey]['service_countries'], function( thatKey, thatValue ) {
              if ( thatValue === destinationCountry ) {
                /**
                 * Capture the Narvar EDD furthest date out by looping comparison numerals.
                 * Special note: Narvar returns a date string already converted to the user's timezone.
                 * JavaScript's Date() will convert the string to a date integer representative of UTC timezone.
                 * Because of this we can not reuse the numeral to cformat the final result, so we will use the
                 * string numerals stored in an array once the comparison numerals have been compared.
                 */
                date_edd = new Date( value['edd_end'] );
                date_edd_arr = value['edd_end'].split( '-' );

                if ( date_edd_furthest === null || date_edd > date_edd_furthest ) {
                  date_edd_furthest = date_edd;
                  date_edd_furthest_arr = date_edd_arr;
                }
              }
            });

          }
        });

        /* If there is a specific match to a service and a carrier code then capture that EDD end date. */
        if ( value['carrier_code'] === carrierCode && value['service_code'] === serviceCode ) {
          date_edd_matched = date_edd;
          date_edd_matched_arr = date_edd_arr;
        }

      }

    });

    /* Make the comparisons to display an appropriate EDD end date. */
    if ( date_edd_matched !== null ) {
      date_edd_displayed = date_edd_matched_arr;
    } else if  ( date_edd_furthest !== null ) {
      date_edd_displayed = date_edd_furthest_arr;
    }

    /* Format the date for display, remeber to convert UTC back to user's timezone. */
    if ( date_edd_displayed !== null ) {
      date_edd_displayed = months[parseInt( date_edd_displayed[1], 10 )-1] + ' ' + days[parseInt( date_edd_displayed[2], 10 )-1];
    } else {
      date_edd_displayed = 'Unknown';
    }

    /* Output the Narvar EDD as a formatted string to the DOM. */
    if ( ! $( '.narvar-edd-request' ).hasClass( 'hidden' ) ) {
      $( '.narvar-edd-request' ).addClass( 'hidden' );
    }

    if ( $( '.narvar-edd-response' ).hasClass( 'hidden' ) ) {
      $( '.narvar-edd-response' ).removeClass( 'hidden' );
    }

    $( '#narvar-edd-formatted' ).html( '<strong>' + date_edd_displayed + '</strong>' );

  }

  init() {
    this.initGetSetDisplay();
    this.initFormSlideToggle();
    this.initRequestListener();

    /* Hook in to the WooCommerce JavaScript API trigger events for `updated_wc_div`. */
    $( document.body ).trigger( 'updated_wc_div' );
    /* Listen for the `updated_wc_div` event. */
    $( 'body' ).on( 'updated_wc_div', this.initGetSetDisplay );

    /* Hook in to the WooCommerce JavaScript API trigger events for `updated_shipping_method`. */
    $( document.body ).trigger( 'updated_shipping_method' );
    /* Listen for the `updated_shipping_method` event. */
    $( 'body' ).on( 'updated_shipping_method', this.initGetSetDisplay );

    $( document.body ).trigger( 'updated_checkout' );
    $( 'body' ).on( 'updated_checkout', this.initGetSetDisplay );
  }

}

export default Narvar;
