
export default {
  props: {
    dataType: {
      type: String,
      default: 'message',
      validator(value) {
        return ['message', 'error'].indexOf(value) !== -1;
      },
    },

    dataButtonLabel: {
      type: String,
    },

    dataButtonLink: {
      type: String,
    },

    dataTimeout: {
      type: Number,
    },
  },

  data() {
    return {};
  },

  computed: {
    type() {
      return this.dataType;
    },

    buttonLabel() {
      return this.dataButtonLabel;
    },

    buttonLink() {
      return this.dataButtonLink;
    },
  },

  methods: {},
};
