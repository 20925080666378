
import Alert from './Alert.vue';
import Logos from './AddLogo/Logos.vue';

export default {
  name: 'add-logo',

  components: {
    Alert,
    Logos,
  },

  props: {
    defaultLogoCategory: {
      type: Number,
      default: 0,
    },

    product: {
      type: Object,
      required: true,
    },

    selectedVariation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      categories: [],
      categoryOption: this.defaultLogoCategory,
      options: [],
      searchValue: '',
      selectedLogoCharge: 0,
      selectedLogoHatIcon: {
        alt: '',
        height: '',
        url: '',
        width: '',
      },
      selectedLogoId: 0,
    };
  },

  computed: {
    /**
     * Constructs the customizations Object and fills its items with data values
     * @returns {Object} - Returns customization {Object}: selectedLogoId {number} | selectedLogoCharge {number}
     */
    customizations() {
      return {
        selectedLogoId: this.selectedLogoId,
        selectedLogoCharge: this.selectedLogoCharge,
      };
    },
  },

  methods: {
    /**
     * Emits changes for customizations and logo selections.
     */
    change() {
      this.$emit('change', {
        customizations: this.customizations,
        selectedLogoHatIcon: this.selectedLogoHatIcon,
      });
    },

    /**
     * Handles update to selected logo.
     * @listens module:AddLogo.logo~event:@event {module:Logo~change~selected-logo-updated}
     */
    handleSelectedLogoUpdated() {
      window.Bus.$on('mdghc.selected-logo-updated', (logo) => {
        this.selectedLogoId = logo.id;
        this.selectedLogoHatIcon = logo.hatIcon;

        this.selectedLogoCharge = parseInt(
          logo.selectedLogo.additional_charge,
          10
        );

        this.change();
      });
    },

    /**
     * Sets category options.
     * @callback mounted
     */
    setSearchCategories(callback) {
      let options = {
        params: {
          action: 'hat_customizer_logo_categories',
          product_id: this.product.id,
        },
      };

      this.$http.get('/', options).then(response => {
        var options =
          typeof response.data.data.options === 'undefined'
            ? []
            : response.data.data.options;

        callback(options);
      });
    },
  },

  mounted() {
    this.setSearchCategories(
      function(options) {
        this.categories = options;
      }.bind(this)
    );

    this.change();

    this.handleSelectedLogoUpdated();
  },
};
