// Create a new Navigation object
class Navigation {

  // Initialize object properties
  constructor() {
    this.desktopNav = $( '.desktop-navigation' );
    this.dropdown = $( '.dropdown' );
    this.dropdownOpen = $( '.dropdown.open' );
    this.dropdownSearch = $( '.search-dropdown' );
    this.header = $( '.main-header' );
    this.mobileNav = $( '.mobile-navigation' );
    this.page = $( 'body' );
    this.scrimDesktop = $( '.scrim-desktop' );
    this.scrimMobile = $( '.scrim-mobile' );
    this.subMenu = $( '.sub-menu' );
    this.toggle = $( '.menu-toggle' );
    this.toggleDropdown = $( '.dropdown-toggle' );
    this.toggleSearch = $( '.search-dropdown-toggle' );
    this.triggers = [this.toggle, this.scrimMobile];
  }

  /**
   * Initialize the mobile menu toggle button
   */
  initToggle() {
    // Apply listeners to each mobile menu trigger as defined in the object properties
    $.each( this.triggers, function( i, obj ) {

      // On click listener, toggle all relevant open/close classes
      $( obj ).on( 'click', function( e ) {
        e.preventDefault();
        this.toggle.toggleClass( 'menu-open' );
        this.mobileNav.toggleClass( 'show' ).toggleClass( 'hide' );
        this.dropdown.removeClass( 'open' );
        this.scrimMobile.toggleClass( 'menu-open' );
        this.page.toggleClass( 'no-scroll' );

        // UX solution for Userback Feedback ID #1335424
        // When opening the mobile menu, set the first menu item to start open to clue the user in to the arrow interactivity
        if (this.toggle.hasClass('menu-open')) {
          this.mobileNav.children().children().children(this.dropdown).first().addClass('open');
        }

        // Close the search dropdown
        this.dropdownSearch.removeClass( 'open' );
        this.toggleSearch.removeClass( 'search-open' );
      }.bind( this )); // Bind the class object to keep context

    }.bind( this )); // Bind the class object to keep context
  }

  /**
   * Initialize the mobile menu dropdown togglers (carets)
   */
  initToggleDropdowns() {
    // On click listener to open/close dropdown sub menus
    $( '.mobile-navigation .dropdown-toggle' ).on( 'click', function( e ) {
      e.preventDefault();
      // Open clicked sub menu
      $( this ).parent().parent( '.dropdown' ).toggleClass( 'open' );
      // Close all open sibling sub menus
      $( this ).parent().parent( '.dropdown' ).siblings( '.dropdown' ).removeClass( 'open' );
    });

    // Set the calculated height of the mobile sub menus for slide down effect
    var subMenuHeight = 0;
    var itemHeight = 0;
    // Loop through top level menu items that contain sub menus
    $( '.mobile-navigation .nav-menu .has-mega-menu' ).each( function() {
      // Loop through all sub menus
      $( this ).find( '.sub-menu' ).each ( function () {
        // Loop through all inner sub menus
        $( this ).find( '.sub-menu' ).each( function () {
          // Loop through all children of inner sub menus
          $( this ).find( '> .menu-item:not(.mega-menu-image)' ).each ( function () {
            // Get menu item height
            itemHeight = $( this ).outerHeight( true );

            // Calculate inner sub-menu height
            subMenuHeight = parseFloat( subMenuHeight ) + parseFloat( itemHeight );

            // Reset item height for next iteration
            itemHeight = 0;
          });

          // Pass the calculated inner sub menu height to a data-* attribute
          // Does not reset to 0 so inner sub menus can be added to parent sub menu's total height
          $( this ).css( '--calculated-height', subMenuHeight + 'px' );
        });

        // Loop through all direct children
        // This is needed so items without sub menus are still counted for the total height calculation
        $( this ).find( '> .menu-item:not(.mega-menu-image)' ).each ( function () {
          // Get menu item height
          itemHeight = $( this ).outerHeight( true );

          // Calculate parent sub-menu height
          subMenuHeight = parseFloat( subMenuHeight ) + parseFloat( itemHeight );

          // Reset item height for next iteration
          itemHeight = 0;
        });

        // Pass the calculated parent sub menu height to a data-* attribute
        // The subMenuHeight was not reset in either of the previous loops,
        // so this will calculate the parent sub menu using all inner sub menus and all menu items
        $( this ).css( '--calculated-height', subMenuHeight + 'px' );

        // Reset sub-menu height for next top level iteration
        subMenuHeight = 0;
      });
    });
  }

  /**
   * Initialize desktop menu dropdowns and scrims.
   * This method calculates each dropdown height and applies the calculated height to the elements.
   */
  initDesktopDropdowns() {
    // Resize each dropdown and scrim to the corresponding sub-menu height
    var megaMenuHeight = 0;
    var dropdownHeight = 0;

    // Loop through all top level menu items with dropdowns
    $( '.desktop-navigation .nav-menu .menu-item.dropdown' ).each( function() {

      // Check for the Mega Menu
      if ( $( this ).hasClass( 'has-mega-menu' ) ) {
        // Loop through all 2nd level sub-menu mega-menu-col-wrap elements
        $( this ).find( '> .sub-menu .mega-menu-col-wrap' ).each( function() {
          megaMenuHeight = parseFloat( $( this ).outerHeight( true ) );

          // Loop through all menu-item elements wrapped in the mega-menu-col-wrap
          $( this ).find( '.menu-item' ).each( function() {
            // Calculate the column height with the sum of the collective menu-item elements
            megaMenuHeight = parseFloat( megaMenuHeight ) + parseFloat( $( this ).outerHeight( true ) );
          });

          // Loop through all mega-menu-image elements wrapped in the mega-menu-col-wrap
          $( this ).find( '.mega-menu-image' ).each( function() {
            // Calculate the column height with the sum of the collective menu-image elements
            megaMenuHeight = parseFloat( megaMenuHeight ) + parseFloat( $( this ).outerHeight( true ) );
          });

          // Assign the calcualted height as the dropdown height if it is the tallest column in the loop
          if ( megaMenuHeight > dropdownHeight ) {
            dropdownHeight = megaMenuHeight;
          }

          // Reset mega menu element height for next iteration
          megaMenuHeight = 0;
        });
      } else {
        // Loop through all 2nd level sub-menu with menu-item
        $( this ).find( '> .sub-menu .menu-item' ).each( function() {
          // Calculate the dropdown height using the sum of the menu-item
          dropdownHeight = parseFloat( dropdownHeight ) + parseFloat( $( this ).outerHeight( true ) );
        });
      }

      // Apply the calculated height to this iteration
      $( this ).find( '.scrim-desktop' ).css( '--calculated-height', dropdownHeight + 'px' );
      $( this ).find( '> .sub-menu' ).css( '--calculated-height', dropdownHeight + 'px' );

      // Reset dropdown height for next iteration
      dropdownHeight = 0;
    });
  }

  /**
   * Initialize desktop menu hover listeners
   */
  initHoverListener() {
    // Binding the class object will not work here,
    // so, use the `that`/`self` var method to allow `this` to keep context.
    let self = this;

    // On mouseover listener for top level dropdowns, open sub menu
    this.desktopNav.find( '.nav-menu > .dropdown > a' ).on( 'mouseover', function() {
      $( this ).parent().addClass( 'open' ); // dropdown
      $( this ).parent().find( self.scrimDesktop ).addClass( 'menu-open' ); // scrim

      /* Target only the submenu with a corresponding data-menu-id, use for unnested divs */
      // var target = $( this ).data( 'target-menu-id' );
      // $( this ).parent().parent().parent().find( `[data-menu-id='${target}']` ).addClass( 'reveal' ); // primary sub-menu

      // Mega Menu target all nested sub-menu levels at once
      $( this ).parent().find( '.sub-menu' ).addClass( 'reveal' ); // all child sub-menu

      $( this ).parent().nextAll( $( '.menu-item a' ) ).addClass( 'highlight' ); // next siblings down the line
    });

    // On mouseleave listener for top level dropdowns, close sub menu
    this.desktopNav.find( '.nav-menu > .dropdown' ).on( 'mouseleave', function() {
      $( this ).removeClass( 'open' ); // dropdown
      $( this ).find( self.scrimDesktop ).removeClass( 'menu-open' ); // scrim

      /* Target only the submenu with a corresponding data-menu-id, use for unnested divs */
      // var target = $( this ).data( 'target-menu-id' );
      // $( this ).parent().parent().find( `[data-menu-id='${target}']` ).removeClass( 'reveal' ); // primary sub-menu

      // Mega Menu target all nested sub-menu levels at once
      $( this ).find( '.sub-menu' ).removeClass( 'reveal' ); // secondary sub-menu

      $( this ).nextAll( $( '.menu-item a' ) ).removeClass( 'highlight' ); // next siblings down the line
    });
  }

  /**
   * Initialize the search form dropdown toggler (icon)
   */
  initToggleSearch() {
    this.toggleSearch.on( 'click', function( e ) {
      e.preventDefault();
      // On click listener to open/close search form dropdown
      this.dropdownSearch.toggleClass( 'open' );
      this.toggleSearch.toggleClass( 'search-open' );

      // Close the mobile menu, reset the toggle button, and close any open dropdowns
      this.toggle.removeClass( 'menu-open' );
      this.mobileNav.removeClass( 'show' ).addClass( 'hide' );
      this.dropdown.removeClass( 'open' );
      this.scrimMobile.removeClass( 'menu-open' );
      this.page.removeClass( 'no-scroll' );
    }.bind( this )); // Bind the class object to keep context

    // Listen for a click outside the search form dropdown
    $( document ).on( 'click', function( e ) {
      if(
        !$( e.target ).closest( this.dropdownSearch ).length &&
        !$( e.target ).closest( this.toggleSearch ).length &&
        this.dropdownSearch.hasClass( 'open' )
      ) {
        this.dropdownSearch.toggleClass( 'open' );
        this.toggleSearch.toggleClass( 'search-open' );
      }
    }.bind( this )); // Bind the class object to keep context
  }

  /**
   * Position (absolute) the navigation menus and related scrims
   */
  positionMenus() {
    var topPosition = 0;
    var leftPosition = 0;

    var headerHeight = this.header.outerHeight( true );
    var desktopNavHeight = this.desktopNav.outerHeight( true );

    // Position the mobile navigation menu
    topPosition = parseFloat( headerHeight );
    $( '.mobile-navigation' ).css( '--calculated-top', topPosition + 'px' );

    // Position the search form dropdown
    $( 'header.main-header' ).find( '.search-dropdown' ).css( '--calculated-top', topPosition + 'px' );

    // Set the calculated height of the mobile sub menus for slide down effect
    topPosition = parseFloat( desktopNavHeight );

    // Loop through all sub-menus
    $( '.desktop-navigation > .nav-menu > .menu-item.dropdown' ).each( function() {
        var offset = $( this ).offset();
        leftPosition = parseFloat( offset.left );

        // Position the desktop navigation primary submenu (or Mega Menu)
        $( this ).find( '> .sub-menu' ).css( '--calculated-top', topPosition + 'px' ).css( '--calculated-left', '-' + leftPosition + 'px' );

        // Position the desktop navigation scrim
        $( this ).find( '> .scrim-desktop' ).css( '--calculated-top', topPosition + 'px' ).css( '--calculated-left', '-' + leftPosition + 'px' );
    });
  }

  /**
   * Handle page scroll callback
   */
  handleScroll() {
    // ...
  }

  /**
   * Handle page resize callback
   */
  handleResize() {
    // Close the mobile menu, reset the toggle button, and clasoe any open dropdowns
    this.toggle.removeClass( 'menu-open' );
    this.mobileNav.removeClass( 'show' ).addClass( 'hide' );
    this.dropdown.removeClass( 'open' );
    this.scrimMobile.removeClass( 'menu-open' );
    this.page.removeClass( 'no-scroll' );

    // Close the search dropdown
    this.dropdownSearch.removeClass( 'open' );
    this.toggleSearch.removeClass( 'search-open' );

    // Re-initialize the desktop menu dropdowns
    this.initDesktopDropdowns();

    // Re-position (absolute) menus and scrims
    this.positionMenus();
  }

  /**
   * Method callback to initialize the Navigation object
   */
  init() {
    this.initToggle();
    this.initToggleDropdowns();
    this.initDesktopDropdowns();
    this.initHoverListener();
    this.positionMenus();
    this.initToggleSearch();

    // Add window resize listener with debounce
    $( window ).on( 'resize', function() {
      setTimeout( this.handleResize(), 500 );
    }.bind( this )); // Bind the class object to keep context

    // Add window scroll listener
    $( window ).on( 'scroll', function() {
      this.handleScroll();
    }.bind( this )); // Bind the class object to keep context
  }
}

// Export class as default
export default Navigation;
