
export default {
  props: {
    dataAttribute: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    attribute() {
      return this.dataAttribute;
    },

    isColorType() {
      return this.attribute.swatch_type === 'color';
    },

    isPhotoType() {
      return this.attribute.swatch_type === 'photo';
    },

    photo() {
      if (!this.attribute.swatch_photo) {
        return {};
      }

      return this.attribute.swatch_photo;
    },

    swatchBorder() {
      return this.attribute.swatch_border;
    },
  },

  methods: {},
};
