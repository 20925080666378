/** jQuery */
import 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/accordion';

// Import font awesome
import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
  faSearch,
  faExternalLinkAlt,
  faChevronRight,
  faChevronLeft,
  faPlayCircle,
  faUser,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faSearch,
  faExternalLinkAlt,
  faChevronRight,
  faChevronLeft,
  faPlayCircle,
  faUser,
  faExclamationCircle
)
dom.watch();


// Import everything from autoload


// Import local dependencies
import Router from './util/router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import checkout from './routes/checkout';
import trackYourOrder from './routes/track';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Checkout page
  checkout,
  // Track Your Order page
  trackYourOrder,
});


/** Vue.js */
import Vue from 'vue';

// Import axios promise based http client (https://axios-http.com/)
import axios from 'axios';

// Instantiate the Vue instance
Vue.config.silent = true;
Vue.prototype.$http = axios;
Vue.prototype.$ajaxUrl = '/web/wp/wp-admin/admin-ajax.php';
Vue.config.productionTip = false;
window.Bus = new Vue();

// Import v-click-outside a Vue directive to react on clicks outside an element without stopping the event propagation
import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);

// Import SweetModal for Vue.js
import { SweetModal } from 'sweet-modal-vue';
Vue.use(SweetModal);
Vue.component('sweet-modal', SweetModal);

import FeaturedContentModal from './vue/FeaturedContentModal';
Vue.component('featured-content-modal', FeaturedContentModal);

// Import MDG Product Enhancements
import ProductVariationAttributeSelectors from './vue/product-enhancements/ProductVariationAttributeSelectors';
import QuickViewActivator from './vue/product-enhancements/QuickViewActivator';
import QuickViewModal from './vue/product-enhancements/QuickViewModal';
import AddToCartAlert from './vue/product-enhancements/AddToCartAlert';
import Slider from './vue/product-enhancements/Slider';
import ProductFilters from './vue/product-enhancements/ProductFilters';
import ImageMessage from './vue/product-enhancements/ImageMessage';
import ProductGallery from './vue/product-enhancements/ProductGallery';
import ProductSku from './vue/product-enhancements/ProductSku';

// Globally register MDG Product Enhancements components
Vue.component('product-variation-attribute-selectors', ProductVariationAttributeSelectors);
Vue.component('mdgpe-quick-view-activator', QuickViewActivator);
Vue.component('mdgpe-quick-view-modal', QuickViewModal);
Vue.component('mdgpe-add-to-cart-alert', AddToCartAlert);
Vue.component('product-filters', ProductFilters);
Vue.component('mdgpe-slider', Slider);
Vue.component('mdgpe-image-message', ImageMessage);
Vue.component('product-gallery', ProductGallery);
Vue.component('product-sku', ProductSku);


/* MDG Hat Customizer */
import HatCustomizer from './hat-customizer/components/Main';
import CustomizerButton from './hat-customizer/components/CustomizerButton';
Vue.component('hat-customizer', HatCustomizer);
Vue.component('customizer-button', CustomizerButton); // Registering via including into the Main.vue isn't working

// Create a Vue Instance
new Vue({ el: '#app' })

// Import Algolia's Instant Search
import AlgoliaInstantSearch from 'vue-instantsearch';
Vue.use(AlgoliaInstantSearch);

// Import Instant Search components
import InstantSearch from './algolia/InstantSearch';

// Only render instant search if enabled via ACF options
if (document.getElementById('algolia-search')) {
  new Vue({
    el: '#header',
    components: {
      InstantSearch,
    },
  })
}

// Load Events
jQuery(document).ready(() => routes.loadEvents());
