
import WcAttributeSelect from './WcAttributeSelect';
import collect from 'collect.js';

export default {
  components: {
    WcAttributeSelect,
  },

  props: {
    dataProduct: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      colorValue: undefined,
      sizeValue: undefined,
      selectedVariation: undefined,
      quantity: 1,
      submitting: false,
    };
  },

  computed: {
    submissionUrl() {
      return '/?wc-ajax=add_to_cart';
    },

    submissionDisabled() {
      if (this.submitting) {
        return true;
      }

      if (!this.isVariable) {
        return false;
      }

      return !this.selectedVariation;
    },

    isVariable() {
      return this.product.type === 'variable';
    },

    product() {
      return this.dataProduct;
    },

    defaultAttributes() {
      if (!this.variationAttributes.default_attributes) {
        return {};
      }

      return this.variationAttributes.default_attributes;
    },

    variations() {
      if (!this.variationAttributes.variations) {
        return {};
      }

      return this.variationAttributes.variations;
    },

    variationAttributes() {
      if (!this.product || !this.product.variation_attributes) {
        return {};
      }

      return this.product.variation_attributes;
    },

    colorOptions() {
      return this.getOptionsForAttribute('attribute_pa_color');
    },

    sizeOptions() {
      return this.getOptionsForAttribute('attribute_pa_size');
    },

    submissonData() {
      if (this.submissionDisabled) {
        return {};
      }
      let productId = this.product.id;
      let productSku = this.product.sku;

      if (this.isVariable) {
        productId = this.selectedVariation.get('variation_id');
        productSku = this.selectedVariation.get('sku');
      }

      return {
        productId: productId,
        quantity: parseInt(this.quantity, 10),
        productSku: productSku,
      };
    },
  },

  methods: {
    handleSubmit() {
      if (this.submissionDisabled) {
        return;
      }

      let formData = new FormData();
      formData.set('product_id', this.submissonData.productId);
      formData.set('quantity', this.submissonData.quantity);
      formData.set('product_sku', this.submissonData.productSku);

      this.submitting = true;
      window.Bus.$emit('mdgqv.add-to-cart.submitting');
      this.$http
        .post(this.submissionUrl, formData)
        .then(({ data }) => {
          this.submitting = false;
          if (data && !!data.error) {
            window.Bus.$emit('mdgqv.add-to-cart.failed');
            return;
          }

          window.scrollTo(0, 0);

          window.Bus.$emit('mdgqv.add-to-cart.succeeded', {
            product: this.product,
          });
        })
        .catch(error => {
          window.Bus.$emit('mdgqv.add-to-cart.failed');
          this.submitting = false;
          console.error(error);
        });
    },

    defaultValueForAttribute(attributeName) {
      if (this.defaultAttributes[attributeName]) {
        return this.defaultAttributes[attributeName];
      }
    },

    getOptionsForAttribute(attributeName) {
      let variations = collect(this.variations);
      return variations
        .pluck('attributes')
        .map(attribute => {
          let term = attribute[attributeName] ? attribute[attributeName] : {};
          return {
            label: term.name,
            value: term.slug,
          };
        })
        .unique(({ value }) => value)
        .all();
    },
  },

  mounted() {
    this.colorValue = this.defaultValueForAttribute('attribute_pa_color');
    this.sizeValue = this.defaultValueForAttribute('attribute_pa_size');

    window.Bus.$on('mdgpe.variation-selected', variation => {
      this.selectedVariation = variation;
    });
  },
};
