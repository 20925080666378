
export default {
  props: {
    dataContent: {
      type: String,
    },
  },

  data() {
    return {
      showingAll: false,
    };
  },

  computed: {
    content() {
      return this.dataContent;
    },

    maxHeight() {
      if (!this.showingAll) {
        return '7.5em';
      }

      return `${this.$refs['content'].offsetHeight}px`;
    },

    moreLabel() {
      return this.showingAll ? 'Close' : 'View More';
    },
  },

  methods: {
    toggleMore() {
      this.showingAll = !this.showingAll;
    },
  },
};
