
export default {
  name: 'personalize-personalize-accordion',

  props: {
    dataOpen: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      open: this.dataOpen,
    };
  },

  methods: {
    toggle() {
      var open = !this.open;
      this.$emit(open ? 'opened' : 'closed');
      this.open = open;
    },
  },

  watch: {
    dataOpen(value) {
      this.open = value;
    },
  },
};
