import ShipStation from '../shipstation/shipstation-api';

export default {
  init() {
    // JavaScript to be fired on the track-your-order page

    /**
     * Construct and initialize the ShipStation API request object.
     */
    const listShipments = new ShipStation(

      /* The endpoint target for the request. */
      this.endpoint = '/shipments',

      /* The input element that contains the user input for the order number. */
      this.inputOrderNumber = $( '#order-number' ),

      /* The button element that triggers the request event. */
      this.buttonSubmitRequest = $( '#submit-track-order' )

    );

    listShipments.init();

  },
};
