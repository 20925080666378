
import InitialsText from './Monogram/InitialsText.vue';
import MonogramFont from './Personalize/EmbroideryFont.vue';
import MonogramThreadColor from './Personalize/ThreadColor.vue';

export default {
  name: 'monogram',

  components: {
    InitialsText,
    MonogramFont,
    MonogramThreadColor,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      details: {},
      firstInitialText: '',
      lastInitialText: '',
      middleInitialText: '',
      monogramFontId: 0,
      monogramThreadColorId: 0,
    };
  },

  computed: {
    customizations() {
      return {
        firstInitialText: this.firstInitialText,
        lastInitialText: this.lastInitialText,
        middleInitialText: this.middleInitialText,
        monogramFontId: this.monogramFontId,
        monogramThreadColorId: this.monogramThreadColorId,
      };
    },
  },

  methods: {
    /**
     * Listen for an emit of 'change' in the MonogramFont component then set the monogramFontId.
     *
     * @method
     * @param {integer} id The monogram font id passed through the emit.
     * @listens component:MonogramFont~emit:change
     */
    onMonogramFontChange(id) {
      this.monogramFontId = id;
      this.change();
      return;
    },

    /**
     * Listen for an emit of 'change' in the MonogramThreadColor component then set the monogramThreadColorId.
     *
     * @method
     * @param {integer} id The monogram thread color id passed through the emit.
     * @listens component:MonogramFont~emit:change
     */
    onMonogramThreadColorChange(id) {
      this.monogramThreadColorId = id;
      this.change();
    },

    /**
     * Once data and comuted values are updated, pass changes to the rest of the components.
     */
    change() {
      this.$emit('change-monogram', this.customizations);
    },

    /**
     * Listen for any initials text changes and update the data value.
     */
    handleInitialsTextChange() {
      window.Bus.$on('mdghc.change-monogram-first-initial', (payload) => {
        this.firstInitialText = payload[0];
      });

      window.Bus.$on('mdghc.change-monogram-last-initial', (payload) => {
        this.lastInitialText = payload[0];
      });

      window.Bus.$on('mdghc.change-monogram-middle-initial', (payload) => {
        this.middleInitialText = payload[0];
      });
    },

    /**
     * Set the monogram details with the PHP defined options.
     */
    setDetails(callback) {
      let options = {
        params: {
          action: 'hat_customizer_monogram_details',
        },
      };

      this.$http.get('/', options).then(response => {
        var details =
          typeof response.data.data.details === 'undefined'
            ? []
            : response.data.data.details;

        callback(details);
      });
    },
  },

  mounted() {
    let vm = this;

    vm.change();

    this.setDetails( function(details) {
      vm.details = details;
    });

    this.handleInitialsTextChange();
  },
};
