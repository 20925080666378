let checkoutOptions = {
  showGiftOption () {
    var giftField = $('#has_gift');
    var giftMessageWrapper = $('#personal_gift_message_field');

    giftField.on('click', function() {
      giftMessageWrapper.toggleClass('show-field');
    });
  },

  showOrderNotesOptions () {
    var orderNotesField = $('#has_order_notes');
    var orderNotesMessageWrapper = $('#order_comments_field');

    orderNotesField.on('click', function() {
      orderNotesMessageWrapper.toggleClass('show-field');
    });
  },
}

export default function() {
  checkoutOptions.showGiftOption();
  checkoutOptions.showOrderNotesOptions();
}
