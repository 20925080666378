
export default {
  data: function () {
    return {
      src: '',
      delayOpen: null,
    }
  },
  mounted: function () {
    // store video src on mount
    this.src = this.$refs.videoModal.$refs.content.querySelector('iframe').src;
  },
  methods: {
    openModal() {
      // reset iframe src back to original
      if (this.$refs.videoModal.$refs.content.querySelector('iframe').src !== this.src) {
        this.$refs.videoModal.$refs.content.querySelector('iframe').src = this.src;

        if (this.delayOpen) {
          clearTimeout(this.delayOpen);
        }
      }

      // reduce modal background flash on open
      this.delayOpen = setTimeout(() => {
        this.$refs.videoModal.open()
      }, 500);
    },
    clearSrc() {
      // reset src on close to stop video
      this.$refs.videoModal.$refs.content.querySelector('iframe').src = '';
    },
  },
};
