
module.exports = {
	name: 'customizer-button',

  data() {
    return {
      inStock: false,
      variationSelected: false,
    };
  },

  props: {
    enableAddLogo: {
      type: Boolean,
      default: false,
    },

    enableAddMonogram: {
      type: Boolean,
      default: false,
    },

    enableAddPersonalization: {
      type: Boolean,
      default: false,
    },

    isInStock: {
      type: Boolean,
      default: false,
    },

    isVariableProduct: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    /**
     * Check to see which combination of customization options are enabled.
     * @returns {string} The correct button label corresponding to the conditions met.
     */
    buttonLabel() {
      let logo = this.enableAddLogo;
      let personalization = this.enableAddPersonalization;
      let monogram = this.enableAddMonogram;

      if ( logo && personalization && !monogram ) {
        return 'Add a logo & personalize';
      } else if ( logo && !personalization && !monogram ) {
        return 'Add a logo';
      } else if ( !logo && personalization && !monogram ) {
        return 'Add personalization';
      } else if ( !logo && !personalization && monogram ) {
        return 'Add a monogram';
      }
    },

    /**
     * Disable/enable the customizer-button based on product in stock status.
     * @returns {boolean} Truthy/falsy value of in stock status (and if variable product the variation selected status).
     */
    isDisabled() {
      if (this.isVariableProduct) {
        return (!this.variationSelected || !this.inStock ? true : false);
      } else {
        return (!this.inStock ? true : false);
      }
    },
  },

  methods: {
    /**
     * Checks for selected variation data from Product Enhancements and sets {data:inStock && data:variationSelected}.
     * @listens {module:emitVariationSelected~event:mdgpe.variation-selected}.
     * @returns {CustomizerButton~data:inStock && CustomizerButton~data:variationSelected} - Updated {inStock} and {variationSelected} truthy/falsy values.
     */
    checkSelectedVariationData() {
      /* Listen for the {mdgpe.variation-selected} event from \MDGPE */
      window.Bus.$on('mdgpe.variation-selected', (payload) => {
        this.inStock = false;
        this.variationSelected = false;

        /* Access the returned selectedVariation data on the ProductVariationAttributeSelectors component (as payload not mdgpeVariations $ref). */
        var selectedVariation = payload; // Dot notation method: this.$root.$refs.mdgpeVariations.selectedVariation;

        /* Set the CustomizerButton's boolean data accordingly. */
        if (typeof selectedVariation !== 'undefined') {
          this.inStock = selectedVariation.is_in_stock;
          this.variationSelected = true;
        }

        return (this.inStock && this.variationSelected);
      });
    },

    openHatCustomizer() {
      window.Bus.$emit('mdghc.open-hat-customizer');
		},
	},

  mounted() {
    if (this.isVariableProduct) {
      this.checkSelectedVariationData();
    } else {
      this.inStock = this.isInStock;
    }
  },
};
