
import AddLogo from './AddLogo.vue';
import AddToCart from './AddToCart.vue';
import CustomizerImage from './CustomizerImage.vue';
import CustomizerTabs from './CustomizerTabs.vue';
import Monogram from './Monogram.vue';
import Personalize from './Personalize.vue';

export default {
  name: 'hat-customizer',

  components: {
    AddLogo,
    AddToCart,
    CustomizerImage,
    CustomizerTabs,
    Monogram,
    Personalize,
  },

  props: {
    customizerImages: {
      type: Object,
      required: true,
    },

    defaultLogoCategory: {
      type: Number,
      default: 0,
    },

    enableAddLogo: {
      type: Boolean,
      default: false,
    },

    enableAddPersonalization: {
      type: Boolean,
      default: false,
    },

    enableAddMonogram: {
      type: Boolean,
      default: false,
    },

    isBeanie: {
      type: Boolean,
      default: false,
    },

    isTshirt: {
      type: Boolean,
      default: false,
    },

    monogramPrice: {
        type: Number,
        default: 10,
    },

    personalizationPrice: {
        type: Number,
        default: 10,
    },

    productId: {
      type: String,
      required: true,
    },

    productPrice: {
      type: String,
      required: true,
    },

    productSku: {
      type: String,
      required: true,
    },

    productTitle: {
      type: String,
      required: true,
    },

    productInventory: {
      type: String,
    },

    requireCustomizationToAddToCart: {
        type: Boolean,
        default: false,
    },
  },

  data() {
    return {
      addLogoActive: true,
      customizations: {
        addLogo: {},
        monogram: {},
        personalize: {},
      },
      customizerOpen: false,
      isInStock: undefined,
      monogramActive: true,
      personalizeActive: true,
      selectedLogoHatIcon: {
        alt: '',
        height: '',
        url: '',
        width: '',
      },
      selectedVariation: undefined,
    }
  },

  computed: {
    /**
     * Returns the current boolean truthy/falsy values for enableAddLogo and enableAddPersonalization props.
     * @returns {HatCustomizer~enableAddLogo} - Returns {boolean} for {prop:enableAddLogo}
     * @returns {HatCustomizer~enableAddPersonalization} - Returns {boolean} for {prop:enableAddPersonalization}
     */
    allCustomizationsEnabled() {
      return this.enableAddLogo && this.enableAddPersonalization;
    },

    /**
     * Constructs and sets an object containing the passed in customizerImages prop.
     * @returns {Object} - Returns images passed in as a collection.
     */
    images() {
      let images = this.customizerImages;
      return images;
    },

    /**
     * Constructs and returns a label string for the Hat Customizer tabs.
     * @returns {string}
     */
    personalizationTabLabel() {
        var price = parseFloat(this.personalizationPrice, 10);
        var priceLabel = price > 0 ? `$${price}` : 'Free';

        return `BACK (${priceLabel})`;
    },

    /**
     * Constructs a product object comprised of the product props passed in to the component.
     * @returns {Object} - Returns the product: id | title | price | sku
     */
    product() {
      return {
        id: parseInt(this.productId),
        title: this.productTitle,
        price: parseFloat(this.productPrice),
        sku: this.productSku,
        is_in_stock: this.productInventory,
      };
    },

    classes() {
      let shirt = this.isTshirt ? ' is-tshirt' : '';
      let beanie = this.isBeanie ? ' is-beanie' : '';
      return `hat-customizer${shirt}${beanie}`;
    },
  },

  methods: {
    /**
     * Checks the selected variation for {is_in_stock} status.
     * @returns {HatCustomizer~isInStock} - Sets the isInStock truthy or falsy value and returns the {data:inStock} item.
     */
    checkStock() {
      return this.isInStock = true; //this.selectedVariation.is_in_stock;
    },

    /**
     * Handles de-rendering the Hat Customizer component and rendering the product details content.
     * @listens module:Main.hat-customizer-header.button~event:@click
     * @returns {HatCustomizer~customizerOpen} - Returns data:customizerOpen falsy value.
     */
    closeHatCustomizer() {
      window.Bus.$emit('mdghc.close-hat-customizer');

      /* Place the WooCommerce product single content back into the container */
      document.querySelector('.single-product .product-wrap').removeAttribute('style');

      let $featured = document.querySelector('.single-product .featured-highlights-bar');
      let $collection = document.querySelector('.single-product .collection-info');
      let $related = document.querySelector('.single-product .related.products');

      if ( document.body.contains( $featured ) ) {
        $featured.removeAttribute('style');
      }

      if ( document.body.contains( $collection ) ) {
        $collection.removeAttribute('style');
      }

      if ( document.body.contains( $related ) ) {
        $related.removeAttribute('style');
      }


      /* Set focus to the top of the product content wrapper after DOM render. */
      this.setWindow();

      /* Conditionally de-render the HatCustomizer form by triggering the v-if directive */
      return this.customizerOpen = false;
    },

    /**
     * Gets the selected variation data from Product Enhancements and sets new data returns.
     * @listens {ref:emitVariationSelected~event:mdgpe.variation-selected}
     * @returns {HatCustomizer~selectedVariation} - Returns the selectedVariation Object.
     */
    getSelectedVariation() {
      /* Listen for the {ref:mdgpe.variation-selected} event from \MDGPE */
      window.Bus.$on('mdgpe.variation-selected', (payload) => {
        /* Access the returned {selectedVariation} data on the ProductVariationAttributeSelectors component (as payload not mdgpeVariations $ref). */
        let selectedVariationObj = payload; // Dot notation method: this.$root.$refs.mdgpeVariations.selectedVariation;

        /* Set the {module:CustomizerButton}'s return data accordingly. */
        if (typeof selectedVariationObj !== 'undefined') {
          this.checkStock();

          this.selectedVariation = selectedVariationObj;

          /* Emit the update so ProductEnhancements can persist from here. */
          window.Bus.$emit('mdghc.selected-variation-updated', this.selectedVariation);

          return this.selectedVariation;
        }

        /* If there are no variations, default to the product (Simple Products) */
        return this.selectedVariation = this.product;
      });
    },

    /**
     * Handles swapping out the Hat Customizer image logo preview.
     * @listens module:Main.add-logo~event:@change {module:AddLogo~change}
     */
    onAddLogoChange(data) {
      this.customizations.addLogo = data.customizations;
      this.selectedLogoHatIcon = data.selectedLogoHatIcon;

      return;
    },

    /**
     * Returns the {customizations.personalize (Object)} on Personalize emit:change listener.
     * @listens component:Personalize~emit:change
     * @returns {data:customizations.personalize} - Sets {data:customizations.personalize}.
     */
    onPersonalizeChange(customizations) {
      return this.customizations.personalize = customizations;
    },

    /**
     * Returns the {customizations.monogram (Object)} on Monogram emit:change listener.
     * @listens component:Personalize~emit:change-monogram
     * @returns {data:customizations.personalize} - Sets {data:customizations.monogram}.
     */
    onMonogramChange(customizations) {
      return this.customizations.monogram = customizations;
    },

    /**
     * Returns the product price on emit 'price-change'.
     * @listens component:AddToCart~emit:price-change
     * @returns {number}
     */
    onPriceChanged(price) {
      return this.price = price;
    },

    /**
     * Handles rendering the Hat Customizer component and de-rendering the product details content.
     * @listens {module:CustomizerButton~event:open-hat-customizer}
     * @returns {data:customizerOpen} - Returns {data:customizerOpen} truthy value.
     */
    openHatCustomizer() {
      window.Bus.$on('mdghc.open-hat-customizer', () => {
        /* De-render the WooCommerce product single content so it doesn't push the contain beyond the HatCustomizer height */
        document.querySelector('.single-product .product-wrap').style.display = 'none';

        let $featured = document.querySelector('.single-product .featured-highlights-bar');
        let $collection = document.querySelector('.single-product .collection-info');
        let $related = document.querySelector('.single-product .related.products');

        if ( document.body.contains( $featured ) ) {
          $featured.style.display = 'none';
        }

        if ( document.body.contains( $collection ) ) {
          $collection.style.display = 'none';
        }

        if ( document.body.contains( $related ) ) {
          $related.style.display = 'none';
        }

        /* Set focus to the top of the product content wrapper after DOM render. */
        this.setWindow();

        /* Conditionally render the HatCustomizer form by triggering the v-if directive */
        return this.customizerOpen = true;
      });
    },

    /**
     * Set focus to the top of the product content wrapper after DOM render.
     * @callback setWindow
     */
    setWindow() {
      const scrollPos = document.getElementById('app').offsetTop;

      window.scrollTo({
        top: scrollPos,
        left: 0,
        behavior: 'instant',
      });

      return;
    },
  },

  mounted() {
    this.getSelectedVariation();
    this.openHatCustomizer();
  },
}
