/**
 * Precisely Requester
 *
 * @class Precisely
 */

 class Precisely {

  /**
   * Initialize the Precisely requester object with all of its parameters.
   * @param {Object} billing - An array object collection of the WooCommerce billing inputs.
   * @param {Object} billingVerificationResponse - Element object of the billing address verification error alert.
   * @param {Object} useShippingAddress - Element object of the WooCommerce use shipping checkbox.
   * @param {Object} shipping - An array object collection of the WooCommerce shipping inputs.
   * @param {Object} shippingVerificationResponse - Element object of the shipping address verification error alert.
   * @param {String} checkoutButton - Selector string of the WooCommerce checkout (Confirm Order) button. This is not a JQuery element object because WooCommerce re-renders the button at various points of the checkout process (such as filling a required address field), meaning we have to go and select that fresh instance every time.
   * @property {Object} targetMessageDiv - Indicates which VerificationElement is currently being used to display any validation errors to the user.
   * @property {Number} maxMatchScore - The desired maximum for a valid vs invalid matchScore from the API. Any matchScore greater than this number will be considered an invalid address.
   * @property {Number} minConfidence - The desired minimum for a valid vs invalid confidence from the API. Any Confidence score less than this number will be considered an invalid address.
   */
  constructor(billing, billingVerificationResponse, useShippingAddress, shipping, shippingVerificationResponse, checkoutButton) {
    this.billing = billing;
    this.billingVerificationResponse = billingVerificationResponse;
    this.useShippingAddress = useShippingAddress;
    this.shipping = shipping;
    this.shippingVerificationResponse = shippingVerificationResponse;
    this.checkoutButton = checkoutButton;

    // Initialize the default div where we can display error messages
    this.targetMessageDiv = this.billingVerificationResponse;

    // Set default thresholds for the scores we want to allow
    this.maxMatchScore = 2;
    this.minConfidence = 90;
  }

  init() {
    /* Attach focusout listener to the Billing Address collection on init. */
    this.setAddressListener( this.billing );

    /* Listen to the checkbox and toggle the address collection listeners. */
    this.useShippingAddress.on('click', function() {
      if ( this.useShippingAddress.is(':checked') ) {
        /* Use the shipping address. */
        /* Attach focusout listener to the Shipping Address collection and disable the listeners on the Billing Address collection. */
        this.setAddressListener( this.shipping );
        this.unsetAddressListener( this.billing );
        this.checkAddressValues( this.shipping );

        // Remove any errors on Billing if we've enabled the use of Shipping
        if ( ! this.targetMessageDiv.hasClass('hidden') ) {
          this.targetMessageDiv.addClass('hidden');
          this.targetMessageDiv.html('');
        }
        this.targetMessageDiv = this.shippingVerificationResponse;
      } else {
        /* Use the billing address. */
        /* Attach focusout listener to the Billing Address collection and disable the listeners on the Shipping Address collection. */
        this.setAddressListener( this.billing );
        this.unsetAddressListener( this.shipping );
        this.checkAddressValues( this.billing );
        this.targetMessageDiv = this.billingVerificationResponse;
      }
    }.bind( this )); // Bind the class object to keep context.
  }

  /**
   * Attaches a `focusout` listener to each address element that returns boolean verification of conditions met.
   * @param {Object} addressCollection - A collection of WooCommerce address elements (either billing or shipping).
   */
  setAddressListener( addressCollection ) {
    /* Loop through each address element. */
    $.each( addressCollection, function( key, value ) {

      /* Attach a `focusout` listener to each address element. */
      value.on( 'focusout', function () {

        /* Check the condition of the address collection values. */
        // console.log( this.checkAddressValues( addressCollection ) );
        // this.verifyAddress( addressCollection, this.checkAddressValues );
        if ( this.checkAddressValues( addressCollection ) === true ) {
          this.verifyAddress( addressCollection );
        }

      }.bind( this )); // Bind the class object to keep context.

    }.bind( this )); // Bind the class object to keep context.
  }

  /**
   * Detaches a `focusout` listener to each address element.
   * @param {Object} addressCollection - A collection of WooCommerce address elements (either billing or shipping).
   */
  unsetAddressListener( addressCollection ) {
    /* Loop through each address element. */
    $.each( addressCollection, function( key, value ) {

      /* Detach the `focusout` listener from each address element. */
      value.off( 'focusout' );

    });
  }

  /**
   * Check the condition that all address fields must have a value in order to verify the address with Precisely.
   * @callback Precisely~setAddressListener
   * @param {Object} addressCollection - A collection of WooCommerce address elements (either billing or shipping).
   * @returns {boolean} true|false - The truthy/falsy state of the condition that all collection items must have a value.
   */
  checkAddressValues( addressCollection ) {
    let verificationReady = true;

    /* Loop through each billing element. */
    $.each( addressCollection, function( key, value ) {

      /* Run the check only if the field is not `AddressLine2`, which is optional. */
      if ( key !== 'AddressLine2' ) {

        /* Check if the element's value is set. */
        if ( !value.val() ) {
          // console.log( 'PRECISELY: "' + key + '" field empty, verification cancelled.' );
          verificationReady = false;
          return verificationReady;
        } else {
          // console.log( 'PRECISELY: ' + key + ': ' + value.val() );
        }

      }

    });

    return verificationReady;
  }

  /**
   * Make the request to verify the given address with the Precisely API resource.
   * @param {Object} addressCollection - A collection of WooCommerce address elements (either billing or shipping).
   * @returns {Object} JSON array - A response collection from the API endpoint in raw JSON format.
   */
  verifyAddress( addressCollection ) {
    /* Get each address element individually from the collection. */
    var dataCollection = {
      'AddressLine1':addressCollection.AddressLine1.val(),
      'AddressLine2':addressCollection.AddressLine2.val(),
      'City':addressCollection.City.val(),
      'Country':addressCollection.Country.val(),
      'StateProvince':addressCollection.StateProvince.val(),
      'PostalCode':addressCollection.PostalCode.val(),
    };

    /* Output the dataCollection to the console for reference. */
    console.log( dataCollection );

    /* Use AJAX to make a call to the PHP class for address verification without refreshing the page or submitting the checkout form. */
    $.ajax({
      url: document.location.origin + '/wp/wp-admin/admin-ajax.php',
      type: 'POST',
      data: {
        action: 'process_request',
        data: dataCollection,
      },
      error: function ( data ) {
        console.log( data );
      },
      success: function ( response ) {
        /**
         * Return the successful AJAX JSON response from the Precisely Address Verification API service.
         *
         */
        // var jsonData = JSON.parse(response);
        var apiResponse = response[0]['Output'][0];
        console.log( apiResponse ); // Leave this console log in place for debugging

        // Check for a Status.Code, which indicates that there was an issue with the address, or a high match score or low confidence
        // Removed `apiResponse['MatchScore'] < this.maxMatchScore && ` from the condition due to inconsistent results with non-US addresses
        if ( ( !apiResponse['Status'] ) && (apiResponse['Confidence'] >= this.minConfidence) ) {
          // Success -- good match
          // Check if an error is currently being shown. If so, hide it and remove content since it isn't relevant anymore. Enable the checkout button again
          if ( ! this.targetMessageDiv.hasClass('hidden') ) {
            this.targetMessageDiv.addClass('hidden');
            this.targetMessageDiv.html('');
            $( this.checkoutButton ).prop('disabled', false);
            $( this.checkoutButton ).removeAttr('title');
          }
        } else {
          // Failure -- unsatisfactory or no match
          // BlockAddress doesn't format it exactly how we want it, so we build our own
          var address = apiResponse['AddressBlock1'] + '<br>' + apiResponse['AddressBlock2'] + '<br>' + apiResponse['AddressBlock3'];
          var responseHTML = '<p>Unfortunately, we could not verify the address you\'ve provided. ';
          responseHTML += 'You have entered:</p>';
          responseHTML += '<div class="entered-address">' + address + '</div>';
          if (apiResponse['Status.Code']) {
            responseHTML += '<p><strong>Issue:</strong> ' + apiResponse['Status.Description'] + '</p>';
          } else if (apiResponse['Confidence'] < this.minConfidence) {
            responseHTML += '<p><strong>Issue:</strong> You may have a mispelling or incorrect information in your entered address that is preventing its verification.</p>';
          }
          responseHTML += '<p class="contact-support">If you\'re certain that all fields are correct, but are still receiving this error, please reach out to Support by clicking the "Help" button in the bottom right corner of your screen.</p>';

          // Populate the error box with the error content and unhide the error.
          this.targetMessageDiv.html(responseHTML);
          this.targetMessageDiv.removeClass('hidden');

          // Disable the checkout button
          $( this.checkoutButton ).prop('disabled', true);
          $( this.checkoutButton ).prop('title', 'You have issues with your shipping address!');
        }


      }.bind( this ), // Bind the class object to keep context.,
    });

  }

}

export default Precisely;
