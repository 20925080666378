
export default {
  name: 'customizer-image',

  props: {
    icon: {
      type: Object,
      required: true,
    },

    images: {
      type: Object,
      required: true,
    },

    selectedVariation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      customizerImage: {
        url: '',
        width: '',
        height: '',
      },
    };
  },

  methods: {
    /**
     * Gets the customizer selected variation preview image based on the selected variation id.
     * @returns {\HatCustomizer\CustomizerImage~customizerImages} - Returns the customizerImages {Object}.
     */
    getCustomizerImage() {
      var vm = this;

      let defaultCustomizerImage = {
        url: '',
        width: '',
        height: '',
      };

      /* Make sure we have customizer images to pull from. */
      if (typeof this.images === 'undefined') {
        return defaultCustomizerImage;
      }

      let customizerImages = this.images;

      /* Try to find an image to use. */
      if (
        typeof customizerImages[vm.selectedVariation.variation_id] !== 'undefined' &&
        customizerImages[vm.selectedVariation.variation_id].url !== ''
      ) {
        return customizerImages[vm.selectedVariation.variation_id];
      }

      /* Use the first one if it is defined. */
      let imageKeys = Object.keys(customizerImages);
      if (typeof customizerImages[imageKeys[0]] !== 'undefined') {
        return customizerImages[imageKeys[0]];
      }

      /* If all else fails reset it. */
      return defaultCustomizerImage;
    },
  },

  watch: {
    selectedVariation() {
      this.$nextTick(() => {
        this.customizerImage = this.getCustomizerImage();
      })
    },
  },

  mounted() {
      this.customizerImage = this.getCustomizerImage();
  },
};
