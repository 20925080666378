

export default {
	name: 'customizer-tabs',

  props: {
    enableAddLogo: {
      type: Boolean,
      default: false,
    },

    enableAddPersonalization: {
      type: Boolean,
      default: false,
    },

    enableAddMonogram: {
      type: Boolean,
      default: false,
    },

    personalization: {
      type: Boolean,
      required: true,
    },

    personalizationTabLabel: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedTab: 'frontContent',
      logoUpcharge: 0,
    };
  },

  computed: {},

  methods: {
    openTab(target) {
      if (!target) {
        return;
      }

      this.selectedTab = target;
      this.$nextTick(() => {
        const content = this.$refs[target];
        content.focus();
      });
    },

    setLogoUpcharge() {
      window.Bus.$on('mdghc.selected-logo-updated', logo => {
        this.logoUpcharge = logo.selectedLogo.additional_charge;
      });
    },
	},

  mounted() {
    this.setLogoUpcharge();
  },
};
