
export default {
  props: {
    dataSku: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentSku: this.dataSku,
    };
  },

  computed: {
  },

  methods: {
    handleSkuChange() {
      window.Bus.$on('mdgpe.variation-selected', (selectedVariation) => {
        if (typeof selectedVariation !== 'undefined') {
          this.currentSku = selectedVariation.sku;
        }
      }
    )},
  },

  mounted() {
    this.handleSkuChange();
  },
};
