
export default {
  name: 'personalize-personalized-text',

  data() {
    return {
      personalizedText: '',
      externalPersonalizedText: '',
    };
  },

  methods: {
    stripDisallowedText(text) {
      return text.replace(/[^0-9a-z]/gi, '');
    },

    handleChange($event) {
      this.personalizedText = $event.target.value.replace(/[^0-9\sA-Za-z',]/gm, '');
    },

    emitFocus() {
      this.$emit('focus');
    },

    emitBlur() {
      this.$emit('blur');
    },
  },

  watch: {
    personalizedText(newPersonalizedText) {
      this.$emit('change', newPersonalizedText);
    },
  },
};
