
export default {
  props: {
    dataDefaultImages: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    dataVariationImages: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      currentVariationId: undefined,
      currentImage: this.dataDefaultImages.product_image,
      currentAdditionalImages: this.dataDefaultImages.additional_images,
      variationImages: this.dataVariationImages,
    };
  },

  computed: {
  },

  methods: {
    handleVariationSelection() {
      window.Bus.$on('mdgpe.variation-selected', (variationInfo) => {
        if (variationInfo === undefined) {
          // variationInfo is "undefined" when we Reset Selection
          // If we are Resetting Selection, set our images back to their defaults
          this.setImageToDefault();
          this.setAdditionalImagesToDefault();

          return;
        }

        // Set our Current Variation ID for matching it to our variationImages array
        this.currentVariationId = variationInfo.variation_id;

        // Flush previous gallery images from different variations
        this.currentImage = undefined;
        this.currentAdditionalImages = undefined;

        // If we have a featured image set for a variation, change the main variation image
        if (this.variationImages['variations'][this.currentVariationId]['product_image']) {
          this.currentImage = this.variationImages['variations'][this.currentVariationId]['product_image'];
        }

        // If we have additional images set for a variation, change the additional variation images
        if (this.variationImages['variations'][this.currentVariationId]['additional_images'][0]) {
          this.currentAdditionalImages = this.variationImages['variations'][this.currentVariationId]['additional_images'];
        }
      });
    },

    setImageToDefault() {
      this.currentImage = this.dataDefaultImages.product_image;
    },

    setAdditionalImagesToDefault() {
      this.currentAdditionalImages = this.dataDefaultImages.additional_images;
    },
  },

  mounted() {
    this.handleVariationSelection();
  },
};
