
export default {
  name: 'initials-text',

  data() {
    return {
      firstInitialText: '',
      lastInitialText: '',
      middleInitialText: '',
    };
  },

  methods: {
    /**
     * Handle validation and data setting on the initials fields.
     *
     * @method
     * @param {template:input~event:input} $event - An input event.
     * @listens template:input~event:input
     * @returns {string} Validated and cleaned input value.
     */
    handleInitialsChange($event) {
      /* Replace non-valid characters with nothing. */
      var reg_ex = $event.target.value.replace(/[^A-Za-z]/gm, '');

      /* Convert all text to uppercase. */
      reg_ex = reg_ex.toUpperCase();

      /* Get the target element's name. */
      var target_element = $event.target.id;

      /* Set the appropriate data value. */
      if ( target_element === 'first_initial_text' ) {
        this.firstInitialText = reg_ex;
      } else if ( target_element === 'last_initial_text' ) {
        this.lastInitialText = reg_ex;
      } else if ( target_element === 'middle_initial_text' ) {
        this.middleInitialText = reg_ex;
      }

      /* Return the target element's value. */
      return $event.target.value = reg_ex;
    },

    emitFocus() {
      this.$emit('mdghc.focus-monogram-initials');
    },

    emitBlur() {
      this.$emit('mdghc.blur-monogram-initials');
    },
  },

  watch: {
    /* Watch and emit any text change on this field. */
    firstInitialText(value) {
      window.Bus.$emit('mdghc.change-monogram-first-initial', value);
      this.$emit('change'); // Pass the change to the AddToCart component.
    },

    /* Watch and emit any text change on this field. */
    lastInitialText(value) {
      window.Bus.$emit('mdghc.change-monogram-last-initial', value);
      this.$emit('change'); // Pass the change to the AddToCart component.
    },

    /* Watch and emit any text change on this field. */
    middleInitialText(value) {
      window.Bus.$emit('mdghc.change-monogram-middle-initial', value);
      this.$emit('change'); // Pass the change to the AddToCart component.
    },
  },
};
